import { SvgIcon, SvgIconProps } from "@sol/uikit/core/icons/SvgIcon";

export const Send01 = (props: SvgIconProps) => {
    return (
        <SvgIcon variant="fill" {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g id="send-01">
                <path
                    id="Icon (Stroke)"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.0298 5.38416L11.6598 13.7542L14.2649 20.4118C14.3651 20.6678 14.7298 20.6607 14.8199 20.401L20.0298 5.38416ZM10.2456 12.34L18.6156 3.96995L3.59878 9.17986C3.33907 9.26996 3.33196 9.63465 3.58794 9.73482L10.2456 12.34ZM19.5491 1.52912C21.3609 0.900535 23.0992 2.63881 22.4706 4.45062L16.7094 21.0565C16.0101 23.072 13.1798 23.1273 12.4024 21.1406L9.76555 14.4019C9.73549 14.3251 9.6747 14.2643 9.59787 14.2342L2.85914 11.5973C0.87245 10.8199 0.927711 7.98961 2.94323 7.29035L19.5491 1.52912Z"
                    fill="#1A1A1A"
                    fillOpacity="0.45"
                />
            </g>
        </SvgIcon>
    );
};
