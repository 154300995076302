import styled, { css } from "styled-components";

import { SvgIconProps } from "./types";

export const classes = {
    stroke: "colorStroke",
    fill: "colorFill",
};

const DEFAULT_BG_COLOR = "transparent";

const SvgIcon = styled.svg<SvgIconProps>`
    background-color: var(--icon-bg-color, ${DEFAULT_BG_COLOR});
    border-radius: 50%;
    box-sizing: content-box;
    flex-shrink: 0;

    ${({ size = 24 }) => {
        if (size == "auto") {
            return css`
                aspect-ratio: 1;
                width: 1em;
                height: 1em;
            `;
        }

        if (size >= 24) {
            return css`
                border: calc((${size}px - 24px) / 2) solid var(--icon-bg-color, ${DEFAULT_BG_COLOR});
                width: 24px;
                height: 24px;
            `;
        }

        return css`
            border: calc((${size}px - 12px) / 2) solid var(--icon-bg-color, ${DEFAULT_BG_COLOR});
            width: 12px;
            height: 12px;
        `;
    }}

    .${classes.stroke} {
        stroke: var(--icon-color, ${({ theme }) => theme.palette.black.base});
    }

    .${classes.fill} {
        fill: var(--icon-color, ${({ theme }) => theme.palette.black.base});
    }

    path,
    circle,
    ellipse,
    rect,
    line,
    polyline,
    polygon {
        shape-rendering: geometricPrecision;
    }
`;

SvgIcon.defaultProps = {
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    "aria-hidden": true,
    focusable: "false",
};

export default SvgIcon;
