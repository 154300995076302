import { AxiosPromise } from "axios";
import { useMemo, useCallback } from "react";
import { useQuery, QueryKey, QueryConfig } from "react-query";

import { useApi } from "@sol/sdk/SDKProvider";

import { IBriefResource } from "../briefs";
import { IEducationalBriefList } from "./IEducationalBriefList";
import { IClassroomResource } from "../classrooms/IClassroomResource";
import { IProfessionalSituationResource } from "../professionalSituations";
import { IPaginated } from "../types/IPaginated";
import { RelationalFilter, mapRelationalFilter } from "../utils/filters";
import { serialize } from "../utils/url";

type Params<TResult = unknown, TError = unknown> = {
    filters?: {
        briefs?: RelationalFilter<IBriefResource>;
        professionalSituations?: RelationalFilter<IProfessionalSituationResource>;
        classrooms?: RelationalFilter<IClassroomResource>;
    };
    queryKey?: QueryKey;
    orderBy?: "rank";
    order?: "asc" | "desc";
    pagination?: Required<Pick<IPaginated, "page" | "perPage">>;
} & Omit<QueryConfig<TResult, TError>, "queryKey" | "queryFn">;

// TODO: move to utils and handle error handling
const unwrapAPIResult = async <T>(promise: AxiosPromise<T>) => {
    const res = await promise;

    return res.data;
};

const mapFilters = (filters: Params["filters"]) => {
    const { briefs, professionalSituations, classrooms } = filters ?? {};
    return {
        "brief.uuid": mapRelationalFilter(briefs),
        "professionalSituation.uuid": mapRelationalFilter(professionalSituations),
        "classroom.uuid": mapRelationalFilter(classrooms),
    };
};

export const useEducationalBriefsQuery = ({
    filters,
    queryKey,
    order = "desc",
    orderBy = "rank",
    pagination,
    ...options
}: Params<IEducationalBriefList>) => {
    // Retrieve the API axios instance to make requests
    const api = useApi();

    const url = useMemo(
        () =>
            `/educational_briefs${serialize(
                {
                    // Disable pagination on the query if not set
                    ...(!pagination ? { pagination: false } : pagination),
                    [`order[${orderBy}]`]: order,
                    ...mapFilters(filters),
                },
                true,
            )}`,
        [filters, pagination, order, orderBy],
    );

    const queryFn = useCallback(() => unwrapAPIResult(api.get<IEducationalBriefList>(url)), [api, url]);

    return useQuery<IEducationalBriefList>({
        queryKey: queryKey ?? url,
        queryFn,
        config: options,
    });
};
