import { SvgIcon, SvgIconProps } from "@sol/uikit/core/icons/SvgIcon";

export const Plus02 = (props: SvgIconProps) => {
    return (
        <SvgIcon variant="fill" {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g id="plus-02">
                <path
                    id="Icon (Stroke)"
                    fill="#1A1A1A"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 5C12.5523 5 13 5.44772 13 6L13 11H18C18.5523 11 19 11.4477 19 12C19 12.5523 18.5523 13 18 13H13V18C13 18.5523 12.5523 19 12 19C11.4477 19 11 18.5523 11 18L11 13H6C5.44772 13 5 12.5523 5 12C5 11.4477 5.44772 11 6 11H11L11 6C11 5.44772 11.4477 5 12 5Z"
                />
            </g>
        </SvgIcon>
    );
};
