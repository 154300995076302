import { format as formatDate } from "date-fns";
import { fr as dateFR, es as dateES } from "date-fns/locale";
import filesize from "filesize";
import i18n, { InitOptions, TFunction } from "i18next";
import ChainedBackend, { ChainedBackendOptions } from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import resourcesToBackend from "i18next-resources-to-backend";
import { initReactI18next } from "react-i18next";
import { setLocale } from "yup";

const config: InitOptions<ChainedBackendOptions> = {
    debug: false,
    defaultNS: "translation",
    load: "currentOnly",
    lowerCaseLng: true,
    lng: "fr",
    fallbackLng: "fr",
    preload: ["fr"],
    interpolation: {
        format(value, format) {
            if (format === "uppercase") {
                return value.toUpperCase();
            }
            if (format === "filesize") {
                return filesize(value);
            }
            if (value instanceof Date) {
                if (format === "date" && Intl?.DateTimeFormat) {
                    return new Intl.DateTimeFormat(i18n.language, {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                    }).format(value);
                }

                const locale = { fr: dateFR, es: dateES }[i18n.language];
                return formatDate(value, format ?? "", locale ? { locale } : undefined);
            }
            return value;
        },
        escapeValue: false,
    },
    backend: {
        backends: [
            HttpBackend, // if a namespace can't be loaded via normal http-backend loadPath, then the resourcesToBackend will try to return the correct resources
            resourcesToBackend((language: string) => import(`../public/static/langs/${language}.json`)),
        ],
        backendOptions: [
            {
                loadPath: function ([locale]: [string]) {
                    return `/static/langs/${locale}.json`;
                },
            },
        ],
    },
    react: {
        useSuspense: true,
    },
};

const buildYupMessages = (_: any, t: TFunction) => {
    // add translated error messages as you need
    // must follow the yup shape : https://github.com/jquense/yup/blob/master/src/locale.ts
    const yupErrorMessages = {
        array: {
            min: ({ min }: { min: number }) => t("form.errors.array.min", { count: min }),
            max: ({ max }: { max: number }) => t("form.errors.array.max", { count: max }),
        },
        string: {
            min: ({ min }: { min: number }) => t("form.errors.min", { count: min }),
            max: ({ max }: { max: number }) => t("form.errors.tooLong", { count: max }),
            email: () => t("form.errors.email"),
            url: () => t("form.errors.url"),
            length: ({ length }: { length: number }) => t("form.errors.length", { count: length }),
            required: () => t("form.errors.required"),
        },
        mixed: {
            required: () => t("form.errors.required"),
        },
    };

    setLocale(yupErrorMessages);
};

const appI18n = i18n.createInstance(config).use(ChainedBackend).use(initReactI18next);

appI18n.init(config, buildYupMessages);

export const getI18nApiInstance = async (lng = "fr") => {
    const apiI18n = appI18n.cloneInstance({
        react: {
            // Disabled server side suspense
            useSuspense: false,
        },
        lng,
    });

    await apiI18n.init();

    return apiI18n;
};

// quick fix to update html lang attribute
if (typeof window !== "undefined") {
    i18n.on("languageChanged", lang => {
        window.document.documentElement.setAttribute("lang", lang);
        buildYupMessages(null, i18n.t.bind(i18n));
    });
}

export default appI18n;
