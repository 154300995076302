import { Layout } from "antd";
import clsx from "clsx";
import { ComponentPropsWithoutRef, ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Merge } from "type-fest";

import { useAuthenticatedUser } from "@sol/authentication";
import { useActiveClassroom } from "@sol/classrooms";
import { useCheckFeatureFlag } from "@sol/features";

import { ScrollProvider } from "../../../contexts/ScrollContext";
import useAuth from "../../../hooks/useAuth";
import { Menu } from "../../Navigation/Menu";
import { getDropdownMenuItems, getMenuItems, getQuickActionItems } from "../../Navigation/Menu/utils";
import { Notifications } from "../Notifications";
import SkipLinks from "../SkipLinks";
import { AuthenticatedLayoutFooter } from "./AuthenticatedLayoutFooter";

type AuthenticatedLayoutProps = {
    children: ReactNode;
};

const { Content, Header } = Layout;

export function AuthenticatedLayoutContent({
    className,
    children,
    style,
    ...props
}: Merge<ComponentPropsWithoutRef<typeof Content>, { children: ReactNode }>) {
    return (
        <Content
            id="main-content"
            className={clsx(
                "!min-h-[auto] p-4 pt-0 [grid-area:content]",

                className,
            )}
            style={style}
            {...props}
        >
            {children}
        </Content>
    );
}

export function AuthenticatedLayoutHeader({ className, children, ...props }: ComponentPropsWithoutRef<typeof Header>) {
    return (
        <Header className={clsx("p-4 pr-0 [grid-area:header]", className)} {...props}>
            {children}
        </Header>
    );
}

export function AuthenticatedLayout({ children }: AuthenticatedLayoutProps) {
    const [t] = useTranslation();
    const checkFeatureFlag = useCheckFeatureFlag();
    const { user: currentUser } = useAuthenticatedUser();
    const { activeClassroom } = useActiveClassroom();
    const { logout } = useAuth();

    const menuItems = useMemo(
        () => getMenuItems(t, checkFeatureFlag, activeClassroom),
        [t, activeClassroom, checkFeatureFlag],
    );
    const quickLinkItems = useMemo(() => getQuickActionItems({ t, checkFeatureFlag }), [t, checkFeatureFlag]);
    const dropdownItems = useMemo(() => getDropdownMenuItems({ t, logout }), [t]);

    return (
        <>
            <SkipLinks />
            <ScrollProvider>
                <Layout className="h-screen lg:overflow-hidden" hasSider>
                    <Menu
                        menuItems={menuItems}
                        quickActionItems={quickLinkItems}
                        dropdownItems={dropdownItems}
                        currentUser={currentUser}
                    />
                    <Layout
                        style={{ gridTemplateAreas: '"header notifications" "content content" "footer footer"' }}
                        className="relative grid min-h-screen grid-cols-[1fr_auto] grid-rows-[auto_1fr_auto] overflow-auto"
                    >
                        <div className="p-4 pl-0 [grid-area:notifications]">
                            <Notifications />
                        </div>

                        {children}

                        <AuthenticatedLayoutFooter className="[grid-area:footer]" />
                    </Layout>
                </Layout>
            </ScrollProvider>
        </>
    );
}
