import { SvgIcon, SvgIconProps } from "@sol/uikit/core/icons/SvgIcon";

export const MinusCircleContained = (props: SvgIconProps) => {
    return (
        <SvgIcon variant="fill" {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g id="minus-circle-contained">
                <path
                    id="Icon (Stroke)"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM7.625 12C7.625 11.4477 8.07272 11 8.625 11H15.375C15.9273 11 16.375 11.4477 16.375 12C16.375 12.5523 15.9273 13 15.375 13H8.625C8.07272 13 7.625 12.5523 7.625 12Z"
                    fill="#1A1A1A"
                    fillOpacity="0.45"
                />
            </g>
        </SvgIcon>
    );
};
