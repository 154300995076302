import { ItemType, MenuItemType } from "antd/es/menu/interface";
import last from "lodash/last";
import { useRouter } from "next/router";
import { useMemo } from "react";

import { MenuProps } from "./Menu";

/**
 * Finds the paths leading to leaves matching the predicate
 * @param menuItems Menu items to search against
 * @param predicate Matching predicate
 * @param path Initial menu path that will accumulate when traversing tree
 * @returns the paths leading to leaves matching the predicate
 */
const findLeaves = <T extends MenuItemType = MenuItemType>(
    menuItems: ItemType<T>[],
    predicate: (menuItem: ItemType<T>) => boolean,
    path: ItemType<T>[] = [],
): ItemType<T>[][] | null => {
    let result: ItemType<T>[][] = [];
    for (let i = 0; i < menuItems.length; i++) {
        const menuItem = menuItems[i];

        if (menuItem && "children" in menuItem && menuItem.children && menuItem.children.length > 0) {
            // Not a leaf but we can recurse on children
            const matchingPaths = findLeaves(menuItem.children, predicate, [...path, menuItem]);
            if (matchingPaths) {
                result = [...result, ...matchingPaths];
            }
        } else {
            // No children so we are a leaf
            if (predicate(menuItem)) {
                // If we match predicate we have found what we were searching for
                result = [...result, [...path, menuItem]];
            }
        }
    }

    return result.length > 0 ? result : null;
};

/**
 * Use current router path to compute Menu selected and open keys
 * @param items the Menu items
 * @returns Menu selected and open keys
 */
export function useRouteBasedMenuState<T extends MenuItemType = MenuItemType>(items: ItemType<T>[]) {
    const router = useRouter();
    const activeKey = router.asPath.split("?")[0];

    return useMemo((): Pick<MenuProps, "selectedKeys" | "openKeys" | "onSelect"> => {
        // Menu is a tree like structure
        // Selected items are items leading to the leaf corresponding to the current URL
        // its a tree search;
        const paths =
            findLeaves(items, menuItem => {
                const key = menuItem?.key;
                return !!key && activeKey.startsWith(key as string);
            }) ?? [];

        const selections = paths.map(path => path.map(item => item?.key) as string[]);
        const selectedLeafKey = last(
            selections.map(selection => last(selection) as string).sort((a, b) => a.length - b.length),
        );

        const selection = selectedLeafKey
            ? (selections?.find(selection => selection.includes(selectedLeafKey)) ?? [])
            : [];

        return {
            selectedKeys: selection,
            openKeys: selection.length > 1 ? selection.slice(undefined, -1) : [],
            onSelect: ({ key }) => {
                router.push(key, key, { shallow: true });
            },
        };
    }, [items, activeKey, router.push]);
}
