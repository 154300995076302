import { SvgIcon, SvgIconProps } from "@sol/uikit/core/icons/SvgIcon";

export const UsersProfiles02 = (props: SvgIconProps) => {
    return (
        <SvgIcon variant="fill" {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g id="users-profiles-02">
                <path
                    id="Icon (Stroke)"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.71431 5.68555C8.75176 5.68555 7.97145 6.46585 7.97145 7.4284C7.97145 8.39096 8.75176 9.17126 9.71431 9.17126C10.6769 9.17126 11.4572 8.39096 11.4572 7.4284C11.4572 6.46585 10.6769 5.68555 9.71431 5.68555ZM5.97145 7.4284C5.97145 5.36128 7.64719 3.68555 9.71431 3.68555C11.7814 3.68555 13.4572 5.36128 13.4572 7.4284C13.4572 9.49553 11.7814 11.1713 9.71431 11.1713C7.64719 11.1713 5.97145 9.49553 5.97145 7.4284ZM14.7344 4.55177C15.011 4.07371 15.6227 3.91034 16.1007 4.18689C17.2169 4.83253 17.9715 6.04176 17.9715 7.4284C17.9715 8.81505 17.2169 10.0243 16.1007 10.6699C15.6227 10.9465 15.011 10.7831 14.7344 10.305C14.4579 9.82698 14.6212 9.21525 15.0993 8.93871C15.6228 8.63585 15.9715 8.07225 15.9715 7.4284C15.9715 6.78456 15.6228 6.22096 15.0993 5.9181C14.6212 5.64156 14.4579 5.02983 14.7344 4.55177ZM3.60249 14.0124C5.04392 12.8939 7.11201 12.2769 9.71431 12.2769C12.3166 12.2769 14.3847 12.8939 15.8261 14.0124C17.2897 15.1481 18.0286 16.7443 18.0286 18.4855C18.0286 19.3937 17.3435 20.3141 16.2828 20.3141H3.14579C2.08513 20.3141 1.40002 19.3937 1.40002 18.4855C1.40002 16.7443 2.13888 15.1481 3.60249 14.0124ZM3.40358 18.3141H16.025C15.9805 17.2487 15.517 16.304 14.6 15.5925C13.6121 14.8259 12.023 14.2769 9.71431 14.2769C7.40559 14.2769 5.81653 14.8259 4.82858 15.5925C3.91167 16.304 3.44815 17.2487 3.40358 18.3141ZM18.3989 13.087C18.7295 12.6446 19.3561 12.5539 19.7986 12.8845C20.6082 13.4893 21.2947 14.4766 21.7726 15.4485C22.2502 16.4195 22.6 17.5397 22.6 18.4855C22.6 19.3937 21.9149 20.3141 20.8543 20.3141H20.4C19.8477 20.3141 19.4 19.8664 19.4 19.3141C19.4 18.7618 19.8477 18.3141 20.4 18.3141H20.5931C20.5532 17.7951 20.3415 17.0705 19.9779 16.331C19.5726 15.5069 19.0592 14.8286 18.6015 14.4866C18.159 14.1561 18.0684 13.5294 18.3989 13.087Z"
                    fill="#1A1A1A"
                    fillOpacity="0.45"
                />
            </g>
        </SvgIcon>
    );
};
