import { IBriefResource } from "../briefs";
import { IMediaResource, IMediaSecuredResource } from "../media";
import { Embed, ITimestampable } from "../types";
import { IUserResource } from "../users";
import { IWorkResource } from "../works";
import { ITopicResource, TopicStatus } from "./ITopicResource";

export interface ITopicBrief extends Omit<IBriefResource, "coeditors" | "createdBy"> {
    image?: IMediaResource;
}

export interface ILearnerLink {
    url: string;
}

export enum ReflectiveAnalysisStatus {
    NEW = "new",
    IN_PROGRESS = "in_progress",
    EVALUATED = "evaluated",
    SUBMITTED = "submitted",
}

export interface ITopicWork extends IWorkResource {
    learnerLinks: ILearnerLink[];
    mediaSecured: Embed<IMediaSecuredResource, "title" | "url" | "size">[];
    learnerComment: string;
    learnerDatetime: string;
    trainerComment: string;
    trainerDatetime: string;
    trainer: IUserResource;
    validatedSkillLevels: string[];
    unvalidatedSkillLevels: string[];
}

export interface ITopic extends ITimestampable, ITopicResource {
    classroom: string;
    status: TopicStatus;
    learner: IUserResource;
    brief: string;
    lastRenderedWork: ITopicWork;
    works: ITopicWork[];
    reflectiveAnalysisStatus?: ReflectiveAnalysisStatus;
    reflectiveAnalyses?: string[];
}
