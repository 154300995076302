import { isUndefined } from "lodash";
import { useRouter } from "next/router";
import { ReactNode, useMemo, useRef } from "react";
import * as yup from "yup";

import { Feature, useCheckFeatureFlag, useFeatures } from "@sol/features";
import { interpolate, Route, useSearchParams } from "@sol/routing";
import { EnumDictionary } from "@sol/utils";

// WIP: Feature sets should be well defined before filling this dictionnary
const ACCESS_CONTROL_LIST: EnumDictionary<Route, Feature[]> = {
    [Route.TRAINER_WORKSPACE_BRIEFS]: [Feature.BRIEFS],
    [Route.BRIEFS_DETAILS]: [Feature.BRIEFS],
    [Route.BRIEFS_EDIT]: [Feature.BRIEFS_EDIT],
    [Route.CLASSROOM_BRIEFS_CREATE]: [Feature.BRIEFS_CREATE],

    // CLASSROOMS
    [Route.HOME]: [Feature.HOMEPAGE],
    [Route.CLASSROOMS]: [Feature.CLASSROOM],
    [Route.CLASSROOMS_CREATE]: [Feature.CLASSROOM_CREATE],
    [Route.CLASSROOMS_EDIT]: [Feature.CLASSROOM_UPDATE],
    [Route.CLASSROOMS_LEARNERS]: [Feature.CLASSROOM_UPDATE_LEARNERS],

    // BRIEFS
    [Route.CLASSROOM_BRIEFS]: [Feature.BRIEFS],
    // [Route.BRIEFS_ASSIGNED]: [Feature.CLASSROOM_BRIEFS], // TODO: Désactiver l'onglet dans classrooms/<uuid>/briefs
    [Route.BRIEFS_COLLABORATE]: [Feature.BRIEFS_CREATE], // TODO: Désactiver l'onglet dans trainer-workspace/brief
    [Route.BRIEFS_EXPLORE]: [Feature.BRIEFS_EXPLORE, Feature.MENU_BRIEFS_EXPLORER], // TODO: Désactiver l'onglet dans classrooms/<uuid>/briefs
    [Route.ADMIN]: [Feature.ADMIN],

    [Route.BRIEFS_CREATE]: [Feature.BRIEFS_CREATE],
    // TODO: think a new permission system for scoped route access (ex: ErrorBoundary + restricted scope)
    // or we don't care as the page is not reachable through user experience (no such a link is displayed)
    [Route.CLASSROOM_BRIEFS_EDIT]: [Feature.BRIEFS_CREATE],
    [Route.CLASSROOM_BRIEFS_DETAILS]: [Feature.BRIEFS],
    [Route.OLD_CLASSROOM_BRIEFS_DETAILS]: [Feature.BRIEFS],
    [Route.CLASSROOM_BRIEFS_ASSIGN]: [Feature.BRIEFS_CREATE],
    [Route.CLASSROOM_BRIEFS_GROUP_ASSIGN]: [Feature.BRIEFS_CREATE],

    // USER
    [Route.PROFILE]: [Feature.PROFILE],
    [Route.PROFILE_EDIT]: [Feature.PROFILE],

    // PUBLIC
    [Route.GDPR_DISCLAIMER]: [],
    [Route.PAGE]: [],
    [Route.NOTIFICATION_CALLBACK]: [Feature.NOTIFICATIONS],

    // ANONYMOUS
    [Route.LOGIN]: [Feature.LOGIN],
    [Route.RESET_PASSWORD]: [Feature.LOGIN],
    [Route.REGISTRATION]: [Feature.REGISTER],

    [Route.CLASSROOM_WORKSPACES]: [Feature.TOPICS, Feature.WORKSPACE_GROUP],
    [Route.CLASSROOM_WORKSPACES_REFLECTIVE_ANALYSIS_DETAILS]: [Feature.TOPICS, Feature.WORKSPACE_GROUP],

    // FOLLOW UPS
    [Route.FOLLOW_UPS_LIST]: [Feature.FOLLOW_UPS],
    [Route.FOLLOW_UPS_CREATE]: [Feature.FOLLOW_UPS_CREATE],
    [Route.FOLLOW_UPS_DETAILS]: [Feature.FOLLOW_UPS],
    [Route.FOLLOW_UPS_EDIT]: [Feature.FOLLOW_UPS_EDIT],

    // RESOURCES
    [Route.CLASSROOM_RESOURCES]: [Feature.RESOURCES],

    // PROFESSIONAL SITUATIONS
    [Route.TRAINER_WORKSPACE_PROFESSIONAL_SITUATION_CREATE]: [Feature.PROFESSIONAL_SITUATION_CREATE],
    [Route.TRAINER_WORKSPACE_PROFESSIONAL_SITUATION_EDIT]: [Feature.PROFESSIONAL_SITUATION_UPDATE],
    [Route.TRAINER_WORKSPACE_PROFESSIONAL_SITUATION]: [Feature.PROFESSIONAL_SITUATION],

    // MISSIONS
    [Route.MISSION_CREATE]: [Feature.MISSION_CREATE],

    // FRAMEWORKS
    [Route.TRAINER_WORKSPACE_FRAMEWORKS]: [Feature.FRAMEWORKS],
    [Route.FRAMEWORKS]: [Feature.FRAMEWORKS_ACCESS_TUTOR],

    // Pedagogical Scenario
    [Route.CLASSROOM_PEDAGOGICAL_SCENARIO]: [Feature.PEDAGOGICAL_SCENARIO],
    [Route.CLASSROOM_PEDAGOGICAL_SCENARIO_SCHEDULE]: [Feature.PROFESSIONAL_SITUATION],

    // Classroom Dashboard
    [Route.CLASSROOM_DASHBOARD]: [Feature.MENU_CLASSROOM_DASHBOARD],

    // WORKSPACES
    [Route.WORKSPACES]: [Feature.WORKSPACES_ACCESS_TUTOR],
    [Route.WORKSPACES_REFLECTIVE_ANALYSIS_DETAILS]: [Feature.WORKSPACES_ACCESS_TUTOR],

    // LEARNERS
    [Route.LEARNERS]: [Feature.LEARNERS_CARD_ACCESS_TUTOR],
};

type Props = { placeholder?: ReactNode; children: ReactNode };

const redirectQuerySchema = yup.object({
    redirect: yup.string().matches(new RegExp("^/.*")),
});

const sanitizeRedirect = (value: undefined | string) => {
    if (isUndefined(value)) {
        return;
    }

    if (yup.string().url().isValidSync(value)) {
        return;
    }

    return value;
};

export const RouteAccessGuard = ({ children }: Props) => {
    const features = useFeatures();
    const checkFeatureFlag = useCheckFeatureFlag();
    const router = useRouter();
    const { route, replace, asPath } = router;

    const { redirect } = redirectQuerySchema.cast(useSearchParams());

    const redirectRef = useRef<string | null>(null);

    const defaultPage = useMemo(() => {
        if (checkFeatureFlag(Feature.HOMEPAGE)) {
            return Route.HOME;
        } else if (checkFeatureFlag(Feature.ADMIN)) {
            return Route.ADMIN;
        } else if (checkFeatureFlag(Feature.PROFILE)) {
            return Route.PROFILE;
        }

        return interpolate(Route.LOGIN, { redirect: asPath });
    }, [checkFeatureFlag, asPath]);

    const allow = useMemo(() => {
        const acl: Feature[] = ACCESS_CONTROL_LIST[route as Route] || [];

        // Allow by default
        if (acl.length === 0) {
            return true;
        }

        // Allow only if one of our features match acl
        return acl.findIndex(feature => features.has(feature)) > -1;
    }, [route, features]);

    if (redirectRef.current) {
        replace(`${window.location.origin}${redirectRef.current}`);
        redirectRef.current = null;
        return null;
    }

    if (allow) {
        return children;
    }

    redirectRef.current = sanitizeRedirect(redirect) ?? null;
    replace(defaultPage);

    return null;
};

export default RouteAccessGuard;
