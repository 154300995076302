import { SvgIcon, SvgIconProps } from "@sol/uikit/core/icons/SvgIcon";

export const XCircleContained = (props: SvgIconProps) => {
    return (
        <SvgIcon variant="fill" {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g id="x-circle-contained">
                <path
                    id="Icon (Stroke)"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM8.11091 8.11091C8.50144 7.72039 9.1346 7.72039 9.52513 8.11091L12 10.5858L14.4749 8.11091C14.8654 7.72039 15.4986 7.72039 15.8891 8.11091C16.2796 8.50144 16.2796 9.1346 15.8891 9.52513L13.4142 12L15.8891 14.4749C16.2796 14.8654 16.2796 15.4986 15.8891 15.8891C15.4986 16.2796 14.8654 16.2796 14.4749 15.8891L12 13.4142L9.52513 15.8891C9.1346 16.2796 8.50144 16.2796 8.11091 15.8891C7.72039 15.4986 7.72039 14.8654 8.11091 14.4749L10.5858 12L8.11091 9.52513C7.72039 9.1346 7.72039 8.50144 8.11091 8.11091Z"
                    fill="#1A1A1A"
                    fillOpacity="0.45"
                />
            </g>
        </SvgIcon>
    );
};
