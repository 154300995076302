import { getResourceUuid } from "./getResourceUuid";
import { IHydraResource } from "../types/IHydraResource";

export const getPlaceholderPicture = (resource: Pick<IHydraResource, "@id">) => {
    const PICTURE_PATHS = [
        "/static/placeholders/picture_placeholder_blue.svg",
        "/static/placeholders/picture_placeholder_green.svg",
        "/static/placeholders/picture_placeholder_grey.svg",
        "/static/placeholders/picture_placeholder_purple.svg",
        "/static/placeholders/picture_placeholder_red.svg",
        "/static/placeholders/picture_placeholder_yellow.svg",
    ];

    const uuid = getResourceUuid(resource);

    const numericId = parseInt(uuid, 16);
    const index = numericId % PICTURE_PATHS.length;

    return PICTURE_PATHS[index];
};
