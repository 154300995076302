import { SvgIcon, SvgIconProps } from "@sol/uikit/core/icons/SvgIcon";

export const ChevronDown = (props: SvgIconProps) => {
    return (
        <SvgIcon variant="fill" {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g id="chevron-down">
                <path
                    id="Icon (Stroke)"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.26255 9.32461C6.63556 8.91732 7.26812 8.88954 7.6754 9.26255L12.0007 13.2239L16.3245 9.26266C16.7317 8.88958 17.3643 8.91726 17.7374 9.32449C18.1104 9.73171 18.0827 10.3643 17.6755 10.7374L12.6763 15.3174C12.2941 15.6675 11.7077 15.6676 11.3254 15.3175L6.32461 10.7375C5.91732 10.3644 5.88954 9.73189 6.26255 9.32461Z"
                    fill="#1A1A1A"
                    fillOpacity="0.45"
                />
            </g>
        </SvgIcon>
    );
};
