import { Button } from "antd";
import { ReactNode, RefObject, useEffect, useRef, useState } from "react";
import { useTranslation, WithTranslation, withTranslation } from "react-i18next";
import { Text } from "src/uikit";
import styled from "styled-components";

import { INotificationResource } from "@sol/sdk";
import { Loader, TrashCan } from "@sol/uikit/core/icons";
import { Typography } from "@sol/uikit/core/Typography";

import SOLNotificationItemContainer from "./SOLNotificationItem";
import SOLNotificationItem from "./SOLNotificationItem/SOLNotificationItem";

const NOTIFICATIONS_CONTAINER_MAX_HEIGHT = "364px";

type NoNotificationProps = {
    className?: string;
};

const NoNotification = styled(({ className }: NoNotificationProps) => {
    const [t] = useTranslation();

    return (
        <div className={className}>
            <Text variant="label">{t("component.navbar.noNotifications")}</Text>
        </div>
    );
})`
    background: url("/static/hero-bg.svg");
    height: 128px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const NotificationsList = styled.ul`
    height: ${NOTIFICATIONS_CONTAINER_MAX_HEIGHT};
    padding: 0;
    margin: 0;
    overflow-y: auto;
`;

type Props = {
    allNotifs: INotificationResource[];
    infiniteScroll: (v: ReactNode[]) => ReactNode;
    handleClickNotif: (v: INotificationResource) => void;
    removeOneNotif: (v: INotificationResource) => void;
    removeAllNotif: () => void;
    showLoader: boolean;
    transformNewToSeen: () => void;
    className?: string;
    listRef?: RefObject<HTMLDivElement>;
} & WithTranslation;

const SOLNotification = ({
    allNotifs,
    handleClickNotif,
    removeOneNotif,
    removeAllNotif,
    transformNewToSeen,
    className,
    t,
    listRef,
    showLoader,
}: Props) => {
    const [isPending, setIsPending] = useState<boolean>(false);
    const notificationsRef = useRef<HTMLUListElement>(null);

    useEffect(() => {
        transformNewToSeen();
    }, []);

    const handleScroll = () => {
        if (
            notificationsRef.current &&
            notificationsRef.current.scrollHeight - notificationsRef.current.scrollTop ===
                notificationsRef.current.clientHeight
        ) {
            setIsPending(true);
        }
    };

    return (
        <div className={className} onScroll={handleScroll} ref={listRef}>
            {allNotifs.length === 0 ? (
                <NoNotification />
            ) : (
                <NotificationsList>
                    {allNotifs.map(notif => (
                        <li key={notif["@id"]}>
                            <SOLNotificationItemContainer
                                handleClickNotif={handleClickNotif}
                                removeOneNotif={removeOneNotif}
                                notif={notif}
                            />
                        </li>
                    ))}
                </NotificationsList>
            )}
            {allNotifs.length > 0 && (
                <div className="flex h-16 items-center justify-center px-6 py-2">
                    {!isPending || !showLoader ? (
                        <Button
                            type="text"
                            onClick={removeAllNotif}
                            className="inline-flex items-center gap-3 rounded-lg capitalize"
                            danger
                        >
                            <Typography variant="label">{t("navbar.deleteAll")}</Typography>
                            <TrashCan />
                        </Button>
                    ) : (
                        <Loader />
                    )}
                </div>
            )}
        </div>
    );
};

export default styled(withTranslation()(SOLNotification))`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: calc(100% + 16px);
    right: 0;
    z-index: ${({ theme }) => theme.zIndex.navigation};
    overflow: hidden;

    max-height: ${NOTIFICATIONS_CONTAINER_MAX_HEIGHT};
    width: 408px;

    border: 2px solid ${({ theme }) => theme.palette.purple.lighter};
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
    background-color: ${({ theme }) => theme.palette.white.base};

    ${SOLNotificationItem} + ${SOLNotificationItem} {
        margin-bottom: ${({ theme }) => theme.spacing[2]};
    }
`;
