import { Feature } from "@sol/features";
import { UserRole } from "@sol/sdk";
import { EnumDictionary } from "@sol/utils";

export const authenticatedFeatures = [Feature.PROFILE];

/*
 * NOTE: This file is intended for module internal use only.
 * Please rely on features module instead
 */

export const AnonymousFeatures = [Feature.LOGIN, Feature.REGISTER, Feature.RESET_PASSWORD];

export const UserRoleFeatureMap: EnumDictionary<UserRole, Feature[]> = {
    [UserRole.LEARNER]: [
        ...authenticatedFeatures,

        Feature.HOMEPAGE,
        Feature.HOMEPAGE_LEARNER,

        Feature.HOMEPAGE_CLASSROOM_CARD,
        Feature.HOMEPAGE_BRIEFS_CARD,
        Feature.HOMEPAGE_FRAMEWORKS_CARD,
        Feature.HOMEPAGE_METRICS_CARD,
        Feature.HOMEPAGE_RESOURCES_CARD,
        Feature.HOMEPAGE_SCENARIO_CARD,

        Feature.SELECT_ACTIVE_CLASSROOM,
        Feature.CLASSROOM_METRICS,
        Feature.CLASSROOM_WEBINAR,

        Feature.TOPICS,
        Feature.TOPICS_MESSAGE,
        Feature.TOPICS_WORK_SUBMIT,

        Feature.BRIEFS,
        Feature.BRIEFS_EXPLORE,

        Feature.FOLLOW_UPS,
        Feature.FOLLOW_UPS_CREATE,
        Feature.FOLLOW_UPS_CREATE_LEARNER,
        Feature.FOLLOW_UPS_EDIT,
        Feature.FOLLOW_UPS_EDIT_LEARNER,
        Feature.FOLLOW_UPS_NOTIFICATIONS_LEARNER,

        Feature.PROFILE_SKILLS_VIEW,

        Feature.NOTIFICATIONS,

        Feature.WORKSPACE_GROUP,
        Feature.WORKSPACE_GROUP_LIST,
        Feature.WORKSPACE_GROUP_MESSAGE,
        Feature.WORKSPACE_GROUP_SUBMIT,

        Feature.FOLLOW_UPS,
        Feature.FOLLOW_UPS_EDIT,

        Feature.RESOURCES,
        Feature.RESOURCE_CREATE,

        Feature.MENU_HOME,
        Feature.MENU_CLASSROOM_SPACE,
        Feature.MENU_FOLLOW_UPS,
        Feature.MENU_PAST_CLASSROOM_HISTORY,
        Feature.MENU_CLASSROOM_DASHBOARD,

        Feature.CLASSROOM_BRIEFS,

        Feature.PEDAGOGICAL_SCENARIO,
        Feature.STATISTICS,
        Feature.REFLECTIVE_ANALYSIS_SUBMIT,
    ],
    [UserRole.TRAINER]: [
        ...authenticatedFeatures,
        Feature.HOMEPAGE,
        Feature.HOMEPAGE_TRAINER,

        Feature.HOMEPAGE_CLASSROOM_CARD,
        Feature.HOMEPAGE_METRICS_CARD,
        Feature.HOMEPAGE_LEARNERS_CARD,
        Feature.HOMEPAGE_BRIEFS_CARD,
        Feature.HOMEPAGE_FRAMEWORKS_CARD,
        Feature.HOMEPAGE_CLASSROOM_SCOPED_FRAMEWORKS,
        Feature.HOMEPAGE_RESOURCES_CARD,
        Feature.HOMEPAGE_SCENARIO_CARD,

        Feature.SELECT_ACTIVE_CLASSROOM,
        Feature.CLASSROOM_UPDATE_LEARNERS,
        Feature.CLASSROOM_UPDATE,
        Feature.CLASSROOM_METRICS,
        Feature.CLASSROOM_METRICS_TRAINER,
        Feature.CLASSROOM_LEARNERS,
        Feature.CLASSROOM_BRIEFS,
        Feature.CLASSROOM_BRIEFS_MANAGE,
        Feature.CLASSROOM_WEBINAR,
        Feature.CLASSROOM_DOWNLOAD_BALANCE_SHEET,
        Feature.EXPORT_CLASSROOM_STATISTICS,

        Feature.PROFILE_CLASSROOMS_VIEW,

        Feature.NOTIFICATIONS,

        Feature.TOPICS,
        Feature.TOPICS_MESSAGE,
        Feature.TOPICS_WORK_EVAL,

        Feature.BRIEFS,
        Feature.BRIEFS_CREATE,
        Feature.BRIEFS_EDIT,
        Feature.BRIEFS_EXPLORE,
        Feature.BRIEFS_MANAGE,

        Feature.WORKSPACE_GROUP,
        Feature.WORKSPACE_GROUP_MESSAGE,
        Feature.WORKSPACE_GROUP_MANAGE,
        Feature.WORKSPACE_GROUP_CORRECTION,

        Feature.FOLLOW_UPS,
        Feature.FOLLOW_UPS_LIST_LEARNER_SELECT,
        Feature.FOLLOW_UPS_CREATE,
        Feature.FOLLOW_UPS_CREATE_TRAINER,
        Feature.FOLLOW_UPS_EDIT,
        Feature.FOLLOW_UPS_EDIT_TRAINER,

        Feature.RESOURCES,
        Feature.RESOURCE_CREATE,

        Feature.PROFESSIONAL_SITUATION_CREATE,
        Feature.PROFESSIONAL_SITUATION_UPDATE,
        Feature.PROFESSIONAL_SITUATION,

        Feature.PEDAGOGICAL_SCENARIO_MANAGE,

        Feature.USERS_MANAGE_TUTORS,

        Feature.MENU_HOME,
        Feature.MENU_TRAINER_SPACE,
        Feature.MENU_CLASSROOM_SPACE,
        Feature.MENU_QUICK_LINKS_BRIEF_CREATE,
        Feature.MENU_QUICK_LINKS_PROFESSIONAL_SITUATION_CREATE,
        Feature.MENU_FOLLOW_UPS,
        Feature.MENU_CLASSROOM_DASHBOARD,

        Feature.FRAMEWORKS,
        Feature.STATISTICS,
        Feature.PEDAGOGICAL_SCENARIO,
        Feature.CLASSROOM,
    ],
    [UserRole.MANAGER]: [
        ...authenticatedFeatures,
        Feature.HOMEPAGE,
        Feature.HOMEPAGE_MANAGER,

        Feature.HOMEPAGE_CLASSROOM_CARD,
        Feature.HOMEPAGE_FRAMEWORKS_CARD,
        Feature.HOMEPAGE_METRICS_CARD,
        Feature.HOMEPAGE_LEARNERS_CARD,
        Feature.HOMEPAGE_BRIEFS_CARD,
        Feature.HOMEPAGE_SCENARIO_CARD,
        Feature.HOMEPAGE_CLASSROOM_SCOPED_FRAMEWORKS,

        Feature.PROFILE_CLASSROOMS_VIEW,

        Feature.SELECT_ACTIVE_CLASSROOM,
        Feature.CLASSROOM_CREATE,
        Feature.CLASSROOM_UPDATE,
        Feature.CLASSROOM_UPDATE_LEARNERS,
        Feature.CLASSROOM_METRICS,
        Feature.CLASSROOM_METRICS_TRAINER,
        Feature.CLASSROOM_LEARNERS,
        Feature.CLASSROOM_BRIEFS,
        Feature.CLASSROOM_WEBINAR,
        Feature.CLASSROOM_DOWNLOAD_BALANCE_SHEET,
        Feature.EXPORT_CLASSROOM_STATISTICS,

        Feature.TOPICS,

        Feature.WORKSPACE_GROUP,

        Feature.BRIEFS,
        // WIP - To replace
        //Feature.BRIEFS_CREATE,
        Feature.BRIEFS_EXPLORE,

        Feature.FOLLOW_UPS,
        Feature.FOLLOW_UPS_LIST_LEARNER_SELECT,
        Feature.FOLLOW_UPS_CREATE,
        Feature.FOLLOW_UPS_CREATE_MANAGER,
        Feature.FOLLOW_UPS_EDIT,
        Feature.FOLLOW_UPS_EDIT_MANAGER,

        Feature.USERS_MANAGE_TUTORS,

        Feature.HOMEPAGE_RESOURCES_CARD,
        Feature.RESOURCES,

        Feature.ADMIN,

        Feature.MENU_HOME,
        Feature.MENU_CLASSROOM_SPACE,
        Feature.MENU_CLASSROOM_DASHBOARD,
        Feature.MENU_ASSIGNED_BRIEFS,
        Feature.MENU_FOLLOW_UPS,
        Feature.MENU_BRIEFS_EXPLORER,

        Feature.PEDAGOGICAL_SCENARIO,
        Feature.STATISTICS,
    ],
    [UserRole.ADMIN]: [...authenticatedFeatures, Feature.ADMIN],
    [UserRole.SUPERADMIN]: [...authenticatedFeatures, Feature.ADMIN],
    [UserRole.TUTOR]: [
        ...authenticatedFeatures,
        Feature.HOMEPAGE,
        Feature.HOMEPAGE_TUTOR,

        Feature.HOMEPAGE_LEARNERS_CARD,
        Feature.HOMEPAGE_FRAMEWORKS_CARD,

        Feature.NOTIFICATIONS,

        Feature.BRIEFS,
        Feature.TOPICS,
        Feature.TOPICS_MESSAGE,

        Feature.WORKSPACE_GROUP,
        Feature.WORKSPACE_GROUP_MESSAGE,

        Feature.FOLLOW_UPS,
        Feature.FOLLOW_UPS_LIST_LEARNER_SELECT,
        Feature.FOLLOW_UPS_CREATE,
        Feature.FOLLOW_UPS_CREATE_TUTOR,
        Feature.FOLLOW_UPS_EDIT,
        Feature.FOLLOW_UPS_EDIT_TUTOR,

        Feature.MENU_HOME,
        Feature.MENU_FOLLOW_UPS,
        Feature.MENU_WORKS,

        Feature.FRAMEWORKS_ACCESS_TUTOR,
        Feature.WORKSPACES_ACCESS_TUTOR,
        Feature.LEARNERS_CARD_ACCESS_TUTOR,
    ],
};

export default UserRoleFeatureMap;
