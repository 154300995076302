// GET /classroom/{uuid}/educational_scenario?unit=day

import { AxiosPromise } from "axios";
import { useMemo, useCallback } from "react";
import { useQuery, QueryKey, QueryConfig } from "react-query";

import { useApi } from "@sol/sdk/SDKProvider";

import { IClassroomResource } from "../classrooms/IClassroomResource";
import { IHydraList } from "../types";
import { EducationalScenarioSlot } from "./IEducationalScenarioSlot";
import { mapRelationalValue, RelationalValue } from "../utils/filters";
import { serialize } from "../utils/url";

type Params<TResult = unknown, TError = unknown> = {
    classroom?: RelationalValue<IClassroomResource>;
    filters?: {
        scale?: "month" | "week" | "day";
    };
    queryKey?: QueryKey;
} & Omit<QueryConfig<TResult, TError>, "queryKey" | "queryFn">;

// TODO: move to utils and handle error handling
const unwrapAPIResult = async <T = any>(promise: AxiosPromise<T>) => {
    const res = await promise;

    return res.data;
};

const mapFilters = (filters: Params["filters"]) => {
    const { scale } = filters || {};
    return {
        scale,
    };
};

export const useEducationalScenario = ({ classroom, filters, queryKey, enabled, ...options }: Params<any>) => {
    // Retrieve the API axios instance to make requests
    const api = useApi();

    const url = useMemo(
        () =>
            classroom
                ? `/educational_scenario/classrooms/${mapRelationalValue(classroom)}${serialize(
                      mapFilters(filters),
                      true,
                  )}`
                : "",
        [classroom, filters],
    );

    const queryFn = useCallback(() => unwrapAPIResult(api.get<IHydraList<EducationalScenarioSlot>>(url)), [api, url]);

    return useQuery<IHydraList<EducationalScenarioSlot>>({
        queryKey: queryKey || url,
        queryFn,
        config: { ...options, enabled: enabled ?? !!url },
    });
};
