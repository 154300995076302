import { Menu as AntMenu, type MenuProps as AntMenuProps } from "antd";
import styled, { css } from "styled-components";

import { Chevron } from "@sol/icons";

export type MenuProps = AntMenuProps;

// TODO: improve typography enforcement and reuse
const subheading = css`
    font-size: 0.75rem; /* 12px */
    line-height: 1rem; /* 16px */
    font-style: normal;
    font-weight: 600;
    font-family: var(--ant-font-family);
`;

// Not in design system
// TODO: take design decision to follow antdesign conventions
const f1 = css`
    font-size: 0.75rem; /* 12px */
    line-height: 1rem; /* 16px */
    font-style: normal;
    font-weight: 700;
    font-family: var(--ant-font-family);
`;

const labelsm = css`
    font-size: 0.625rem; /* 10px */
    line-height: 0.75rem; /* 12px */
    font-style: normal;
    font-weight: 600;
    font-family: var(--ant-font-family);
`;

const expandIcon = () => <Chevron className="ant-menu-submenu-expand-icon-custom" direction="down" size={12} />;

const StyledMenu = styled(AntMenu)`
    &.ant-menu-root {
        border: 0;
        position: relative;

        &:focus-visible {
            outline-offset: calc(-1 * var(--ant-line-width-focus));
        }

        .ant-menu-title-content {
            ${subheading}
        }

        > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item > .ant-menu-title-content,
        > .ant-menu-submenu > .ant-menu-submenu-title > .ant-menu-title-content,
        > .ant-menu-item > .ant-menu-title-content {
            ${f1}
        }

        .ant-menu-item-group-title {
            ${labelsm}
        }

        .ant-menu-item:has(> .ant-menu-title-content a:focus-visible),
        .ant-menu-submenu-title:focus-visible {
            outline: var(--ant-line-width-focus) solid var(--ant-color-primary-border);
            outline-offset: 2px;
            transition:
                outline-offset 0s,
                outline 0s;
        }

        .ant-menu-submenu-title,
        .ant-menu-item {
            position: relative;
            overflow: visible;

            padding: var(--ant-padding-xxs) !important;
            border-radius: var(--ant-border-radius-sm);

            --icon-color: currentColor;

            .ant-menu-title-content {
                flex: 1;
                margin-left: 0;
            }

            > .ant-menu-title-content {
                padding-left: var(--ant-menu-icon-margin-inline-end);
            }
        }

        &.ant-menu-inline-collapsed > .ant-menu-submenu-selected > .ant-menu-submenu-title {
            background-color: var(--ant-menu-item-selected-bg);
        }

        > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item.ant-menu-item-selected,
        > .ant-menu-submenu-selected > .ant-menu-submenu-title,
        > .ant-menu-item.ant-menu-item-selected {
            --_menu-item-bar-overflow: var(--ant-padding-xxs);

            &::before {
                pointer-events: none;
                content: "";
                position: absolute;
                left: calc(-2px + -1 * var(--ant-menu-item-margin-inline));
                top: calc(-1 * var(--_menu-item-bar-overflow) / 2);
                width: 4px;
                height: calc(var(--ant-menu-item-height) + var(--_menu-item-bar-overflow));
                background-color: var(--ant-menu-item-selected-color);
                border-radius: 9999px;
            }
        }

        .ant-menu.ant-menu-sub {
            margin-top: calc(-1 * var(--ant-menu-item-margin-block) / 2);
        }

        .ant-menu-item-group-title {
            padding-block: var(--ant-padding-xxs);
        }

        > .ant-menu-submenu {
            .ant-menu-item-group-title {
                margin-inline: var(--ant-menu-item-margin-inline);
                margin-bottom: var(--ant-padding-xs);
                padding-inline: var(--ant-padding-xxs);

                margin-left: calc(var(--ant-menu-icon-size) + var(--ant-menu-item-margin-inline));
            }

            .ant-menu-sub .ant-menu-item-group-title {
                padding-inline-start: var(--ant-padding-xxs);
            }

            .ant-menu-item-group-list > .ant-menu-item,
            .ant-menu.ant-menu-sub > .ant-menu-item {
                margin-left: calc(var(--ant-menu-item-margin-inline) + var(--ant-menu-icon-size));
                width: calc(var(--ant-menu-item-width) - var(--ant-menu-icon-size));
            }
        }
    }

    &.ant-menu-inline-collapsed .ant-menu-submenu-expand-icon-custom {
        display: none;
    }

    .ant-menu-submenu-expand-icon-custom {
        transform: rotateZ(180deg);
    }

    .ant-menu-submenu-open > .ant-menu-submenu-title .ant-menu-submenu-expand-icon-custom {
        transform: rotateZ(0);
    }
`;

export function Menu({ theme, expandIcon: customExpandIcon, ...props }: MenuProps) {
    return <StyledMenu expandIcon={customExpandIcon ?? expandIcon} {...props} />;
}
