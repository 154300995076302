import { AxiosPromise } from "axios";
import { useMemo, useCallback } from "react";
import { useQuery, QueryKey, QueryConfig } from "react-query";

import { useApi } from "@sol/sdk/SDKProvider";

import { IProfessionalSituationResource, ISkillResource } from "..";
import { ISkillList } from "./ISkillList";
import { IFrameworkResource } from "../frameworks/IFrameworkResource";
import { IPaginated } from "../types/IPaginated";
import { RelationalFilter, mapRelationalFilter } from "../utils/filters";
import { serialize } from "../utils/url";

type Params<TResult = unknown, TError = unknown> = {
    queryKey?: QueryKey;
    filters?: {
        officialTitle?: string;
        shortTitle?: string;
        frameworks?: RelationalFilter<IFrameworkResource>;
        professionalSituations?: RelationalFilter<IProfessionalSituationResource>;
        skills?: RelationalFilter<ISkillResource>;
        by_learner?: string;
    };
    orderBy?: "officialTitle" | "shortTitle" | "createdAt" | "updatedAt" | "rank";
    order?: "asc" | "desc";
    pagination?: Required<Pick<IPaginated, "page" | "perPage">>;
} & Omit<QueryConfig<TResult, TError>, "queryKey" | "queryFn">;

// TODO: move to utils and handle error handling
const unwrapAPIResult = async <T>(promise: AxiosPromise<T>) => {
    const res = await promise;

    return res.data;
};

const mapFilters = (filters: Params["filters"]) => {
    const { officialTitle, shortTitle, frameworks, professionalSituations, skills, by_learner } = filters || {};
    return {
        officialTitle,
        shortTitle,
        by_learner,
        "frameworks.uuid": mapRelationalFilter(frameworks),
        "professionalSituations.uuid": mapRelationalFilter(professionalSituations),
        uuid: mapRelationalFilter(skills),
    };
};

export const useSkills = ({
    queryKey,
    filters,
    order = "desc",
    orderBy,
    pagination,
    ...options
}: Params<ISkillList>) => {
    // Retrieve the API axios instance to make requests
    const api = useApi();

    const url = useMemo(
        () =>
            `/skills${serialize(
                {
                    // Disable pagination on the query if not set
                    ...(!pagination ? { pagination: false } : pagination),
                    ...(orderBy && { [`order[${orderBy}]`]: order }),
                    ...mapFilters(filters),
                },
                true,
            )}`,
        [filters, pagination, order, orderBy],
    );

    const queryFn = useCallback(() => unwrapAPIResult(api.get<ISkillList>(url)), [api, url]);

    return useQuery<ISkillList>({
        queryKey: queryKey || url,
        queryFn,
        config: options,
    });
};
