import { AxiosPromise } from "axios";
import { useMemo, useCallback } from "react";
import { useQuery, QueryKey, QueryConfig } from "react-query";

import { useApi } from "@sol/sdk/SDKProvider";

import { ILanguageList } from "./ILanguageList";
import { IPaginated } from "../types/IPaginated";
import { serialize } from "../utils/url";

type Params<TResult = unknown, TError = unknown> = {
    queryKey?: QueryKey;
    pagination?: Required<Pick<IPaginated, "page" | "perPage">>;
} & Omit<QueryConfig<TResult, TError>, "queryKey" | "queryFn">;

// TODO: move to utils and handle error handling
const unwrapAPIResult = async <T>(promise: AxiosPromise<T>) => {
    const res = await promise;

    return res.data;
};

export const useLanguages = ({ queryKey, pagination, ...options }: Params<ILanguageList>) => {
    // Retrieve the API axios instance to make requests
    const api = useApi();

    const url = useMemo(
        () =>
            `/languages${serialize(
                {
                    // Disable pagination on the query if not set
                    ...(!pagination ? { pagination: false } : pagination),
                },
                true,
            )}`,
        [pagination],
    );

    const queryFn = useCallback(() => unwrapAPIResult(api.get<ILanguageList>(url)), [api, url]);

    return useQuery<ILanguageList>({
        queryKey: queryKey || url,
        queryFn,
        config: options,
    });
};
