import { cva } from "class-variance-authority";
import React from "react";

import { SvgIconProps, SvgIcon } from "@sol/uikit/core/icons/SvgIcon";

type Direction = "up" | "down" | "left" | "right";

type Props = { direction?: Direction; className?: string };

const chevronVariants = cva(["transition-transform", "ease-in-out", "transition-200"], {
    variants: {
        direction: {
            up: ["rotate-0"],
            down: ["rotate-180"],
            left: ["rotate-[270deg]"],
            right: ["rotate-90"],
        },
    },
});

export const Chevron = ({ direction, className, ...props }: Props & SvgIconProps) => {
    return (
        <SvgIcon {...props} className={chevronVariants({ direction, className })}>
            <path d="M18 14L12 8L6 14" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </SvgIcon>
    );
};
