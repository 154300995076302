import { SvgIcon, SvgIconProps } from "@sol/uikit/core/icons/SvgIcon";

export const Calendar01 = (props: SvgIconProps) => {
    return (
        <SvgIcon variant="fill" {...props} width="25" height="24" viewBox="0 0 25 24" fill="none">
            <g id="calendar-01">
                <path
                    id="Icon (Stroke)"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.80952 2C7.36181 2 7.80952 2.44772 7.80952 3V3.54285H16V3C16 2.44772 16.4477 2 17 2C17.5523 2 18 2.44772 18 3V3.60368C19.7144 3.92073 21 5.43847 21 7.24285V18.3C21 20.3317 19.3701 22 17.3333 22H6.66667C4.62995 22 3 20.3317 3 18.3V7.24285C3 5.50594 4.19126 4.03463 5.80952 3.64423V3C5.80952 2.44772 6.25724 2 6.80952 2ZM6.78991 5.54285H6.66667C5.75787 5.54285 5 6.29222 5 7.24285V7.91425H19V7.24285C19 6.29222 18.2421 5.54285 17.3333 5.54285H6.82914C6.82262 5.54297 6.81608 5.54304 6.80952 5.54304C6.80297 5.54304 6.79643 5.54297 6.78991 5.54285ZM19 9.91425H5V18.3C5 19.2506 5.75787 20 6.66667 20H17.3333C18.2421 20 19 19.2506 19 18.3V9.91425Z"
                    fill="#1A1A1A"
                    fillOpacity="0.45"
                />
            </g>
        </SvgIcon>
    );
};
