/**
 * TODO - Rework with tailwind
 */
import React from "react";

import { SvgIcon, SvgIconProps } from "@sol/uikit/core/icons/SvgIcon";

type Props = { className?: string };

export const DEG_TO_RAD = Math.PI / 180;
export const partialCircle = (cx: number, cy: number, r: number, startAngle: number, endAngle: number) => {
    const start = DEG_TO_RAD * startAngle;
    const end = DEG_TO_RAD * endAngle;

    const length = end - start;
    if (length === 0) {
        throw new Error();
    }

    const fromX = r * Math.cos(start) + cx;
    const fromY = r * Math.sin(start) + cy;
    const toX = r * Math.cos(end) + cx;
    const toY = r * Math.sin(end) + cy;
    const large = Math.abs(length) <= Math.PI ? "0" : "1";
    const sweep = length < 0 ? "0" : "1";

    return `M${fromX} ${fromY} A${r} ${r} 0 ${large} ${sweep} ${toX} ${toY}`;
};

export const D = partialCircle(12, 12, 7, 0, 270);

export const Loading = (props: SvgIconProps & Props) => {
    return (
        <SvgIcon {...props} variant={"stroke"}>
            <path d={D} strokeWidth="2" strokeLinecap="round" />
        </SvgIcon>
    );
};
