import { AxiosPromise } from "axios";
import { useMemo, useCallback } from "react";
import { useQuery, QueryKey, QueryConfig } from "react-query";

import { useApi } from "@sol/sdk/SDKProvider";

import { IEducationalBrief } from "./IEducationalBrief";
import { mapRelationalValue } from "../utils/filters";

type Params<TResult = unknown, TError = unknown> = {
    educationalBrief?: string;
    queryKey?: QueryKey;
} & Omit<QueryConfig<TResult, TError>, "queryKey" | "queryFn">;

// TODO: move to utils and handle error handling
const unwrapAPIResult = async <T>(promise: AxiosPromise<T>) => {
    const res = await promise;

    return res.data;
};

export const useEducationalBrief = ({
    educationalBrief,
    queryKey,
    enabled = true,
    ...options
}: Params<IEducationalBrief>) => {
    // Retrieve the API axios instance to make requests
    const api = useApi();

    const url = useMemo(
        () => (educationalBrief ? `/educational_briefs/${mapRelationalValue(educationalBrief)}` : ""),
        [educationalBrief],
    );

    const queryFn = useCallback(() => unwrapAPIResult(api.get<IEducationalBrief>(url)), [api, url]);

    return useQuery<IEducationalBrief>({
        queryKey: queryKey ?? url,
        queryFn,
        config: { ...options, enabled: !!url && enabled },
    });
};
