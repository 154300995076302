/**
 * Application routes as template
 * @see interpolate function to inject params
 */
export enum Route {
    LOGIN = "/login",
    RESET_PASSWORD = "/reset-password",
    HOME = "/",

    CLASSROOMS = "/classrooms",
    CLASSROOMS_CREATE = "/classrooms/create",

    BRIEFS_CREATE = "/trainer-workspace/briefs/create",
    BRIEFS_EDIT = "/trainer-workspace/briefs/[id]/edit",
    BRIEFS_DETAILS = "/trainer-workspace/briefs/[id]",
    BRIEFS_COLLABORATE = "/briefs/collaborate",
    BRIEFS_EXPLORE = "/briefs/explore",

    PROFILE = "/profile",
    PROFILE_EDIT = "/profile/edit",
    ADMIN = "/admin",

    GDPR_DISCLAIMER = "/gdpr-settings",
    REGISTRATION = "/registration",

    /**
     * Follow-ups list page
     */
    FOLLOW_UPS_LIST = "/follow-ups",
    FOLLOW_UPS_CREATE = "/follow-ups/create",
    FOLLOW_UPS_DETAILS = "/follow-ups/[followUpId]",
    FOLLOW_UPS_EDIT = "/follow-ups/[followUpId]/edit",

    /**
     * Mission creation page
     */
    MISSION_CREATE = "/mission/create",

    FRAMEWORKS = "/frameworks",

    OLD_CLASSROOM_BRIEFS_DETAILS = "/briefs/[briefId]",

    /**
     * Classroom space
     */
    CLASSROOMS_EDIT = "/classrooms/[classroomId]/edit",
    CLASSROOM_BRIEFS = "/classrooms/[classroomId]/briefs",
    CLASSROOM_BRIEFS_CREATE = "/classrooms/[classroomId]/briefs/create",
    CLASSROOM_BRIEFS_EDIT = "/classrooms/[classroomId]/briefs/[id]/edit",
    CLASSROOM_BRIEFS_DETAILS = "/classrooms/[classroomId]/briefs/[id]",
    CLASSROOM_BRIEFS_ASSIGN = "/classrooms/[classroomId]/briefs/[id]/assign",
    CLASSROOM_BRIEFS_GROUP_ASSIGN = "/classrooms/[classroomId]/briefs/[id]/group-assign",
    CLASSROOM_RESOURCES = "/classrooms/[classroomId]/resources",
    CLASSROOM_DASHBOARD = "/classrooms/[classroomId]/dashboard",
    CLASSROOMS_LEARNERS = "/classrooms/[classroomId]/learners",

    CLASSROOM_PEDAGOGICAL_SCENARIO = "/classrooms/[classroomId]/pedagogical-scenario",
    CLASSROOM_PEDAGOGICAL_SCENARIO_SCHEDULE = "/classrooms/[classroomId]/pedagogical-scenario/schedule",

    CLASSROOM_WORKSPACES = "/classrooms/[classroomId]/workspaces",

    CLASSROOM_WORKSPACES_REFLECTIVE_ANALYSIS_DETAILS = "/classrooms/[classroomId]/workspaces/reflective-analyses/[reflectiveAnalysisId]",

    /**
     * Trainer space
     */
    TRAINER_WORKSPACE_BRIEFS = "/trainer-workspace/briefs",
    TRAINER_WORKSPACE_FRAMEWORKS = "/trainer-workspace/frameworks",
    TRAINER_WORKSPACE_PROFESSIONAL_SITUATION = "/trainer-workspace/professional-situations",
    TRAINER_WORKSPACE_PROFESSIONAL_SITUATION_CREATE = "/trainer-workspace/professional-situations/create",
    TRAINER_WORKSPACE_PROFESSIONAL_SITUATION_EDIT = "/trainer-workspace/professional-situations/[id]/edit",

    PAGE = "/pages/[pageUUID]",

    WORKSPACES = "/workspaces",
    WORKSPACES_REFLECTIVE_ANALYSIS_DETAILS = "/workspaces/reflective-analyses/[reflectiveAnalysisId]",

    LEARNERS = "/learners",

    NOTIFICATION_CALLBACK = "/notifications",
}

export default Route;
