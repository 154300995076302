import { Input as AntInput, InputProps as AntInputProps } from "antd";
import { FC } from "react";
import styled from "styled-components";

type InputProps = AntInputProps;

const StyledSearchInput = styled(AntInput.Search)`
    & .ant-btn {
        padding-inline: 3px;
    }

    & .ant-btn.ant-btn-primary {
        padding-inline: var(--ant-padding-xs);
    }
` as typeof AntInput.Search;

const Input: FC<InputProps> & {
    Search: typeof StyledSearchInput;
    Group: typeof AntInput.Group;
    Password: typeof AntInput.Password;
    TextArea: typeof AntInput.TextArea;
    OTP: typeof AntInput.OTP;
} = props => {
    return <AntInput {...props} />;
};

Input.Search = StyledSearchInput;
Input.Group = AntInput.Group;
Input.Password = AntInput.Password;
Input.TextArea = AntInput.TextArea;
Input.OTP = AntInput.OTP;

export default Input;
