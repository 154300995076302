import { AxiosRequestConfig } from "axios";

import { ITopicResource } from "@sol/sdk/topics";
import { IUserResource } from "@sol/sdk/users";
import { createApiQueryHook, IOrderable, IPaginateable, mapPagination, mapOrder } from "@sol/sdk/utils/api";
import { mapRelationalFilter, RelationalFilter } from "@sol/sdk/utils/filters";
import { serialize } from "@sol/sdk/utils/url";

import { IReflectiveAnalysisList } from "../IReflectiveAnalysisList";

interface IReflectiveAnalysisListQueryParameters extends IPaginateable, IOrderable<"createdAt"> {
    filters?: {
        createdBy?: RelationalFilter<IUserResource>;
        topic?: RelationalFilter<ITopicResource>;
    };
}

export type IReflectiveAnalysisListQueryResult = IReflectiveAnalysisList;

const mapFilters = (filters: IReflectiveAnalysisListQueryParameters["filters"]) => {
    const { createdBy, topic } = filters ?? {};

    return {
        "createdBy.uuid": mapRelationalFilter(createdBy),
        "individualAssignment.uuid": mapRelationalFilter(topic),
    };
};

export function reflectiveAnalysisListQuery({
    filters,
    order,
    pagination,
}: IReflectiveAnalysisListQueryParameters): AxiosRequestConfig {
    return {
        method: "GET",
        url: `/reflective_analyses/${serialize(
            {
                ...mapPagination(pagination),
                ...mapOrder(order),
                ...mapFilters(filters),
            },
            true,
        )}`,
    };
}

export const useReflectiveAnalysisListQuery = createApiQueryHook<
    IReflectiveAnalysisListQueryParameters,
    IReflectiveAnalysisListQueryResult
>(reflectiveAnalysisListQuery);
