import { v4 as uuidV4 } from "uuid";

import EntityTypes from "@sol/sdk/EntityTypes";
import { ITopic } from "@sol/sdk/topics";
import { createApiMutationHook, IApiMutationFunction } from "@sol/sdk/utils/api";
import { mapRelationalValueAsIRI, RelationalValue } from "@sol/sdk/utils/filters";

import {
    IReflectiveAnalysisQuestion,
    IReflectiveAnalysisResource,
    ReflectiveAnalysisType,
} from "../IReflectiveAnalysisResource";

export interface IReflectiveAnalysisCreateMutationParameters {
    questions: IReflectiveAnalysisQuestion[];
    individualAssignment: RelationalValue<ITopic>;
    type: ReflectiveAnalysisType;
}

export type IReflectiveAnalysisCreateResult = IReflectiveAnalysisResource;

export const reflectiveAnalysisCreateMutation: IApiMutationFunction<IReflectiveAnalysisCreateMutationParameters> = ({
    questions,
    individualAssignment,
    type,
}) => {
    return {
        method: "POST",
        url: "/reflective_analyses",
        data: {
            uuid: uuidV4(),
            questions,
            individualAssignment: mapRelationalValueAsIRI(EntityTypes.TOPIC, individualAssignment),
            type,
        },
    };
};

export const useReflectiveAnalysisCreate = createApiMutationHook<
    IReflectiveAnalysisCreateMutationParameters,
    IReflectiveAnalysisCreateResult
>(reflectiveAnalysisCreateMutation);
