import { AxiosRequestConfig } from "axios";

import { createApiQueryHook } from "@sol/sdk/utils/api";
import { mapRelationalValue, RelationalValue } from "@sol/sdk/utils/filters";

import { IReflectiveAnalysisResource } from "../IReflectiveAnalysisResource";
export interface IReflectiveAnalysisQueryParameters {
    reflectiveAnalysis: RelationalValue<IReflectiveAnalysisResource>;
}

export type IReflectiveAnalysisQueryResult = IReflectiveAnalysisResource;

export function reflectiveAnalysisQuery({
    reflectiveAnalysis,
}: IReflectiveAnalysisQueryParameters): AxiosRequestConfig {
    return {
        method: "GET",
        url: `/reflective_analyses/${mapRelationalValue(reflectiveAnalysis)}`,
    };
}

export const useReflectiveAnalysisQuery = createApiQueryHook<
    IReflectiveAnalysisQueryParameters,
    IReflectiveAnalysisQueryResult
>(reflectiveAnalysisQuery);
