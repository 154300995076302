import { SvgIcon, SvgIconProps } from "@sol/uikit/core/icons/SvgIcon";

export const ChevronUp = (props: SvgIconProps) => {
    return (
        <SvgIcon variant="fill" {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g id="chevron-up">
                <path
                    id="Icon (Stroke)"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.3251 9.2628C11.7075 8.91236 12.2943 8.91241 12.6766 9.26291L17.6758 13.8463C18.0829 14.2196 18.1103 14.8521 17.7371 15.2592C17.3639 15.6663 16.7313 15.6938 16.3242 15.3205L12.0007 11.3566L7.67568 15.3206C7.26853 15.6938 6.63597 15.6663 6.2628 15.2591C5.88964 14.852 5.91719 14.2194 6.32433 13.8462L11.3251 9.2628Z"
                    fill="#1A1A1A"
                    fillOpacity="0.45"
                />
            </g>
        </SvgIcon>
    );
};
