import { SvgIcon, SvgIconProps } from "@sol/uikit/core/icons/SvgIcon";

export const Check01 = (props: SvgIconProps) => {
    return (
        <SvgIcon variant="fill" {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g id="check-01">
                <path
                    id="Icon (Stroke)"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.5051 7.69093C17.8967 8.08035 17.8985 8.71351 17.5091 9.10513L10.3496 16.3051C10.1619 16.4939 9.90669 16.6 9.6405 16.6C9.3743 16.6 9.11909 16.4939 8.9314 16.3051L6.49091 13.8509C6.10149 13.4592 6.10328 12.8261 6.4949 12.4366C6.88653 12.0472 7.51969 12.049 7.90911 12.4406L9.6405 14.1818L16.0909 7.69491C16.4803 7.30329 17.1135 7.30151 17.5051 7.69093Z"
                    fill="#1A1A1A"
                    fillOpacity="0.45"
                />
            </g>
        </SvgIcon>
    );
};
