import { AxiosPromise } from "axios";
import { useMemo, useCallback } from "react";
import { useQuery, QueryKey, QueryConfig } from "react-query";

import { useApi } from "@sol/sdk/SDKProvider";

import { IProfessionalSituationResource } from "./IProfessionalSituationResource";
import { mapRelationalValue, RelationalValue } from "../utils/filters";

type Params<TResult = unknown, TError = unknown> = {
    queryKey?: QueryKey;
    professionalSituation?: RelationalValue<IProfessionalSituationResource>;
} & Omit<QueryConfig<TResult, TError>, "queryKey" | "queryFn">;

const unwrapAPIResult = async <T>(promise: AxiosPromise<T>) => {
    const res = await promise;

    return res.data;
};

export const useProfessionalSituation = ({
    queryKey,
    professionalSituation,
    enabled = true,
    ...options
}: Params<IProfessionalSituationResource>) => {
    // Retrieve the API axios instance to make requests
    const api = useApi();

    const endpoint = useMemo(
        () => (professionalSituation ? `/professional_situations/${mapRelationalValue(professionalSituation)}` : ""),
        [professionalSituation],
    );

    const queryFn = useCallback(
        () => unwrapAPIResult(api.get<IProfessionalSituationResource>(endpoint)),
        [api, endpoint],
    );

    return useQuery<IProfessionalSituationResource>({
        queryKey: queryKey ?? endpoint,
        queryFn,
        config: { ...options, enabled: !!endpoint && enabled },
    });
};
