import { AxiosRequestConfig } from "axios";

import { IBriefResource, IClassroomResource, IGroupSpaceList, IUserResource, WorkStatus } from "@sol/sdk";
import { createApiQueryHook, IOrderable, IPaginateable, mapOrder, mapPagination } from "@sol/sdk/utils/api";
import { mapRelationalFilter, OneOrManyFilter, RelationalFilter } from "@sol/sdk/utils/filters";
import { serialize } from "@sol/sdk/utils/url";

export interface IGroupSpaceListQueryParameters
    extends IPaginateable,
        IOrderable<"title" | "createdAt" | "briefTitle" | "lastActivityDate" | "lastGroupWorkDate"> {
    filters?: {
        classrooms?: RelationalFilter<IClassroomResource>;
        briefs?: RelationalFilter<IBriefResource>;
        learners?: RelationalFilter<IUserResource>;
        correctedLearners?: RelationalFilter<IUserResource>;
        lastGroupWorkStatus?: OneOrManyFilter<WorkStatus>;
        briefTitle?: string | null;
        hasCorrections?: boolean;
    };
}

export type useGroupSpaceListQueryResult = IGroupSpaceList;

const mapFilters = (filters: IGroupSpaceListQueryParameters["filters"]) => {
    const { classrooms, briefs, learners, correctedLearners, lastGroupWorkStatus, briefTitle, hasCorrections } =
        filters ?? {};

    return {
        "classroom.uuid": mapRelationalFilter(classrooms),
        "brief.uuid": mapRelationalFilter(briefs),
        "learner.uuid": mapRelationalFilter(learners),
        "groupCorrections.learner.uuid": mapRelationalFilter(correctedLearners),
        lastGroupWorkStatus: lastGroupWorkStatus,
        briefTitle: briefTitle,
        hasCorrections: hasCorrections,
    };
};

export function groupSpaceListQuery({
    pagination,
    order,
    filters,
}: IGroupSpaceListQueryParameters): AxiosRequestConfig {
    return {
        method: "GET",
        url: `/group_spaces${serialize(
            {
                ...mapPagination(pagination),
                ...mapOrder(order),
                ...mapFilters(filters),
            },
            true,
        )}`,
    };
}

export const useGroupSpaceListQuery = createApiQueryHook<IGroupSpaceListQueryParameters, useGroupSpaceListQueryResult>(
    groupSpaceListQuery,
);
