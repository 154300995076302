import { type MenuProps } from "antd";
import { TFunction } from "i18next";

import { Route } from "@sol/routing";
import { Logout, Profile, Settings } from "@sol/uikit/core/icons";
import { Typography } from "@sol/uikit/core/Typography";

import { dropdownItemBaseClasses } from "./baseCssClasses";
import { getLink, MenuItem } from "./getMenuItems";

export type DropdownMenuItems = Required<MenuProps>["items"];

export const getDropdownMenuItems = ({ t, logout }: { t: TFunction; logout: () => void }): MenuItem[] => {
    return [
        getLink({
            href: Route.PROFILE,
            label: (
                <Typography variant="subHeading" tag="span" className="inline-flex items-center gap-1">
                    {t("component.Navigation.Menu.dropdownMenu.items.profile")}
                </Typography>
            ),
            icon: <Profile />,
            className: dropdownItemBaseClasses.join(" "),
        }),
        getLink({
            label: (
                <Typography variant="subHeading" tag="span" className="inline-flex items-center gap-1">
                    {t("component.Navigation.Menu.dropdownMenu.items.settings")}
                </Typography>
            ),
            href: Route.PROFILE_EDIT,
            icon: <Settings />,
            className: dropdownItemBaseClasses.join(" "),
        }),
        {
            label: (
                <button
                    tabIndex={-1}
                    type="button"
                    className="inline-flex items-center gap-1 after:absolute after:inset-0 after:[content:'']"
                    onClick={logout}
                >
                    <Typography variant="subHeading" tag="span">
                        {t("component.Navigation.Menu.dropdownMenu.items.logout")}
                    </Typography>
                </button>
            ),
            icon: <Logout stroke="transparent-base" />,
            key: "logout",
            className: dropdownItemBaseClasses.join(" "),
        },
    ];
};
