import { SvgIcon, SvgIconProps } from "@sol/uikit/core/icons/SvgIcon";

export const ArrowRight = (props: SvgIconProps) => {
    return (
        <SvgIcon variant="fill" {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g id="arrow-right">
                <path
                    id="Icon (Stroke)"
                    fill="#1A1A1A"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.6437 4.27586C13.0436 3.89498 13.6766 3.91042 14.0575 4.31035L20.7241 11.3103C21.092 11.6966 21.092 12.3034 20.7241 12.6897L14.0575 19.6897C13.6766 20.0896 13.0436 20.105 12.6437 19.7241C12.2437 19.3433 12.2283 18.7103 12.6092 18.3103L17.6667 13L4 13C3.44772 13 3 12.5523 3 12C3 11.4477 3.44772 11 4 11L17.6667 11L12.6092 5.68966C12.2283 5.28973 12.2437 4.65675 12.6437 4.27586Z"
                />
            </g>
        </SvgIcon>
    );
};
