import Analytics from "./Analytics";

const isServer = typeof window === "undefined";

const analytics = new Analytics({
    app: "simplonline",
    disabled: isServer,
    environment: process.env.ENV || "local",
    build: process.env.BUILD,
    version: process.env.VERSION || "none",
});

export default analytics;
