import React, { useMemo } from "react";
import styled, { css } from "styled-components";

import { Cross as DissmissIcon, Check, Info, Error } from "@sol/icons";
import { NotificationLevel } from "@sol/notifications";

const LeftSide = styled.div`
    font-family: "Nunito Sans";
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;

    span {
        margin-left: ${({ theme }) => theme.spacing[3]};
    }
`;

type Props = {
    level: NotificationLevel;
    message: string;
    onClose: () => void;
};

const Notification = ({ level, message, onClose, ...props }: Props) => {
    const NotificationIcon = useMemo(
        () => ({
            [NotificationLevel.SUCCESS]: Check,
            [NotificationLevel.INFO]: Info,
            [NotificationLevel.ERROR]: Error,
        }),
        [],
    );

    const Icon = NotificationIcon[level];

    return (
        <div {...props}>
            <LeftSide>
                <Icon circle />
                <span>{message}</span>
            </LeftSide>
            <DissmissIcon onClick={onClose} />
        </div>
    );
};

export default styled(Notification)`
    ${({ theme, level }) => {
        const color =
            level === NotificationLevel.INFO
                ? theme.palette.purple.base
                : level === NotificationLevel.SUCCESS
                  ? theme.palette.green.base
                  : theme.palette.red.base;

        const bgColor =
            level === NotificationLevel.INFO
                ? theme.palette.purple.lighter2
                : level === NotificationLevel.SUCCESS
                  ? theme.palette.green.lightest
                  : theme.palette.red.lightest;

        return css`
            background-color: ${bgColor};
            border: 1px solid ${color};
            box-sizing: border-box;
            box-shadow: 4px 4px 0px ${color};
            border-radius: ${theme.shape.borderRadius.medium};
            padding: 12px 24px;
            width: 650px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: ${color};

            ${LeftSide} {
                --icon-bg-color: ${color};
                --icon-color: ${bgColor};
            }

            ${DissmissIcon} {
                cursor: pointer;
                --icon-color: ${color};
            }
        `;
    }}
`;
