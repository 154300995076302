import { AxiosRequestConfig } from "axios";

import { IGroupSpace, IGroupSpaceResource } from "@sol/sdk";

import { createApiQueryHook } from "../../utils/api";
import { mapRelationalValue, RelationalValue } from "../../utils/filters";

export type IGroupSpaceQueryParameters = {
    groupSpace?: RelationalValue<IGroupSpaceResource>;
};

export type IGroupSpaceQueryResult = IGroupSpace;

export function groupSpaceQuery({ groupSpace }: IGroupSpaceQueryParameters): AxiosRequestConfig | undefined {
    if (!groupSpace) {
        return;
    }

    return {
        method: "GET",
        url: `/group_spaces/${mapRelationalValue(groupSpace)}`,
    };
}

export const useGroupSpaceQuery = createApiQueryHook<IGroupSpaceQueryParameters, IGroupSpaceQueryResult, any>(
    groupSpaceQuery,
);
