import { AxiosRequestConfig, AxiosResponse } from "axios";
import { useState } from "react";
import { MutateConfig, MutateFunction, MutationResult, useMutation } from "react-query";
import { Merge } from "type-fest";
import { v4 as uuid } from "uuid";

import { IMediaSecuredResource } from "@sol/sdk";
import { useApi } from "@sol/sdk/SDKProvider";

import { IMediaResource, MediaType } from ".";

type UseMediaUploadReturnType<TResult, TError, TVariables, TSnapshot> = [
    MutateFunction<TResult, TError, TVariables, TSnapshot>,
    MutationResult<TResult, TError> & { progress: number },
];

type UnSecuredFileType = { file: File; type: MediaType.FILE };
type UnSecuredUrlType = { url: string; type: MediaType.URL };
type SecuredPayload = { file: File; title?: string; secured: true };
type UnSecuredPayload = Merge<{ title?: string; secured: false }, UnSecuredFileType | UnSecuredUrlType>;

type UploadMediaVariables = {
    payload: SecuredPayload | UnSecuredPayload;
    axiosConfig?: AxiosRequestConfig;
};

const useMediaUpload = <
    TResult = AxiosResponse<IMediaResource | IMediaSecuredResource>,
    TError = any,
    TSnapshot = unknown,
>(
    config: MutateConfig<TResult, TError, UploadMediaVariables>,
): UseMediaUploadReturnType<TResult, TError, UploadMediaVariables, TSnapshot> => {
    const api = useApi();
    const [progress, setProgress] = useState(0);

    const uploadMedia = async ({ payload, axiosConfig }: UploadMediaVariables): Promise<TResult> => {
        const { secured, title } = payload;

        const url = secured ? "/media_secureds" : "/media";

        const media = new FormData();

        media.append("uuid", uuid());

        if (title) {
            media.append("title", title);
        }

        if (payload.secured) {
            media.append("file", payload.file);
        } else {
            if (payload.type === MediaType.FILE) {
                media.append("file", payload.file);
                media.append("type", MediaType.FILE);
            } else {
                media.append("url", payload.url);
                media.append("type", MediaType.URL);
            }
        }

        return api.post(url, media, {
            ...axiosConfig,
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress: ({ total, loaded }) => setProgress((loaded / total) * 100),
        });
    };

    const mutation = useMutation<TResult, TError, UploadMediaVariables>(uploadMedia, config);

    return [mutation[0], { ...mutation[1], progress }];
};

export default useMediaUpload;
