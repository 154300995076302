import { AxiosInstance, AxiosResponse } from "axios";
import { useMemo } from "react";
import { MutateConfig, useMutation } from "react-query";

import { useApi } from "@sol/sdk/SDKProvider";

import { IBrief } from ".";

export interface PostBriefVariables
    extends Pick<
        IBrief,
        | "title"
        | "context"
        | "description"
        | "expectedWork"
        | "pedagogicModalities"
        | "performanceCriteria"
        | "status"
        | "isReflectiveAnalysisEnabled"
    > {
    uuid: string;
    skillLevels: string[];
    professionalSituation?: string;
    media?: string[];
    tags?: string[];
    image?: string;
}

export const postBrief = (api: AxiosInstance) => (variables: PostBriefVariables) =>
    api.post<IBrief>("/briefs", variables);

export const useCreateBrief = (config: MutateConfig<AxiosResponse<IBrief>, any, PostBriefVariables>) => {
    const api = useApi();
    const postBriefMutation = useMemo(() => postBrief(api), [api]);
    const [createBrief, { isSuccess, error, reset, isLoading }] = useMutation(postBriefMutation, config);

    return { createBrief, isSuccess, error, reset, isLoading };
};
