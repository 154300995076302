import { SvgIcon, SvgIconProps } from "@sol/uikit/core/icons/SvgIcon";

export const SortVertical01 = (props: SvgIconProps) => {
    return (
        <SvgIcon variant="fill" {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g id="sort-vertical-01">
                <path
                    id="Icon (Stroke)"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.2929 4.09294C11.6834 3.70242 12.3166 3.70242 12.7071 4.09294L16.3071 7.69294C16.6977 8.08347 16.6977 8.71663 16.3071 9.10716C15.9166 9.49768 15.2834 9.49768 14.8929 9.10716L12 6.21426L9.10713 9.10716C8.71661 9.49768 8.08344 9.49768 7.69292 9.10716C7.30239 8.71663 7.30239 8.08347 7.69292 7.69294L11.2929 4.09294ZM7.69292 14.8929C8.08344 14.5024 8.71661 14.5024 9.10713 14.8929L12 17.7858L14.8929 14.8929C15.2834 14.5024 15.9166 14.5024 16.3071 14.8929C16.6977 15.2835 16.6977 15.9166 16.3071 16.3072L12.7071 19.9072C12.5196 20.0947 12.2652 20.2001 12 20.2001C11.7348 20.2001 11.4805 20.0947 11.2929 19.9072L7.69292 16.3072C7.30239 15.9166 7.30239 15.2835 7.69292 14.8929Z"
                    fill="#1A1A1A"
                    fillOpacity="0.45"
                />
            </g>
        </SvgIcon>
    );
};
