import { cva, cx, type VariantProps } from "class-variance-authority";
import React, { FC, HTMLAttributes } from "react";

type SvgIconVariantProps = VariantProps<typeof svgIconVariants>;

const svgIconVariants = cva(
    [
        "shrink-0",
        "border-solid",
        "box-content",
        "shape-geometric-precision",
        "transition-all",
        "ease-in-out",
        "duration-200",
    ],
    {
        variants: {
            size: {
                12: "size-3",
                14: "size-3.5",
                16: "size-4",
                20: "size-5",
                24: "size-6",
                32: "size-8",
                48: "size-12",
                auto: "size-[1em]",
            },
            variant: {
                antd: "sol-antd-icon",
                fill: "sol-icon",
                stroke: "sol-icon",
            },
            rounded: {
                true: "rounded-full",
                false: "rounded-none",
            },
        },
        defaultVariants: {
            size: 24,
            variant: "stroke",
            rounded: false,
        },
    },
);

export interface SvgIconProps extends HTMLAttributes<HTMLOrSVGElement>, SvgIconVariantProps {
    title?: string;
    width?: string;
    height?: string;
    viewBox?: string;
    fill?: string;
    stroke?: string;
    bgColor?: string;
    animation?: string;
    className?: string;
    showFocusableProps?: boolean;
    xmlns?: string;
    children?: React.ReactNode;
}

export const SvgIcon: FC<SvgIconProps> = ({
    size = 24,
    variant = "fill",
    rounded = false,
    showFocusableProps = true,
    width = 24,
    height = 24,
    xmlns = "http://www.w3.org/2000/svg",
    fill,
    viewBox = "0 0 24 24",
    animation,
    children,
    className,
    title,
    ...props
}) => {
    const focusableProps = showFocusableProps
        ? {
              focusable: false,
              "aria-hidden": true,
          }
        : {};

    const combinedClassName = cx([animation, className]);

    return (
        <svg
            className={svgIconVariants({ size, variant, rounded, className: combinedClassName })}
            viewBox={viewBox}
            aria-hidden={!title}
            role={title ? "img" : "presentation"}
            width={width}
            height={height}
            xmlns={xmlns}
            {...focusableProps}
            {...props}
        >
            {children}
        </svg>
    );
};
