import { cva } from "class-variance-authority";
import React from "react";

import { SvgIcon, SvgIconProps } from "./SvgIcon";

type Props = { className?: string };

export const loaderAnimations = cva([
    "[&>*:nth-child(1)]:animate-dot-1",
    "[&>*:nth-child(2)]:animate-dot-2",
    "[&>*:nth-child(6)]:animate-dot-2",
    "[&>*:nth-child(3)]:animate-dot-3",
    "[&>*:nth-child(4)]:animate-dot-4",
    "[&>*:nth-child(8)]:animate-dot-4",
    "[&>*:nth-child(5)]:animate-dot-5",
    "[&>*:nth-child(7)]:animate-dot-5",
    "[&>*:nth-child(9)]:animate-dot-9",
    "[&>*:nth-child(10)]:animate-dot-10",
]);

export const Loader = (props: SvgIconProps & Props) => {
    return (
        <SvgIcon {...props} variant={"stroke"} animation={loaderAnimations()}>
            <path d="M4,9C3.433,9 3,8.567 3,8C3,7.433 3.433,7 4,7C4.567,7 5,7.433 5,8C5,8.567 4.567,9 4,9Z" />
            <path d="M12,9C11.433,9 11,8.567 11,8C11,7.433 11.433,7 12,7C12.567,7 13,7.433 13,8C13,8.567 12.567,9 12,9Z" />
            <path d="M20,9C19.433,9 19,8.567 19,8C19,7.433 19.433,7 20,7C20.567,7 21,7.433 21,8C21,8.567 20.567,9 20,9Z" />
            <path d="M4,13C3.433,13 3,12.567 3,12C3,11.433 3.433,11 4,11C4.567,11 5,11.433 5,12C5,12.567 4.567,13 4,13Z" />
            <path d="M8,13C7.433,13 7,12.567 7,12C7,11.433 7.433,11 8,11C8.567,11 9,11.433 9,12C9,12.567 8.567,13 8,13Z" />
            <path d="M12,13C11.433,13 11,12.567 11,12C11,11.433 11.433,11 12,11C12.567,11 13,11.433 13,12C13,12.567 12.567,13 12,13Z" />
            <path d="M16,13C15.433,13 15,12.567 15,12C15,11.433 15.433,11 16,11C16.567,11 17,11.433 17,12C17,12.567 16.567,13 16,13Z" />
            <path d="M20,13C19.433,13 19,12.567 19,12C19,11.433 19.433,11 20,11C20.567,11 21,11.433 21,12C21,12.567 20.567,13 20,13Z" />
            <path d="M8,17C7.433,17 7,16.567 7,16C7,15.433 7.433,15 8,15C8.567,15 9,15.433 9,16C9,16.567 8.567,17 8,17Z" />
            <path d="M16,17C15.433,17 15,16.567 15,16C15,15.433 15.433,15 16,15C16.567,15 17,15.433 17,16C17,16.567 16.567,17 16,17Z" />
        </SvgIcon>
    );
};
