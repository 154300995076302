import EntityTypes from "../EntityTypes";
import { IHydraResource, ITimestampable } from "../types";
import { IUserResource } from "../users";

export enum ReflectiveAnalysisType {
    START = "start",
    INTERMEDIATE = "intermediate",
    END = "end",
}

export interface IReflectiveAnalysisQuestion {
    question_key: string;
    answer: string;
}

export type IReflectiveAnalysisStatus = IHydraResource;

export interface IReflectiveAnalysisResource extends IHydraResource, ITimestampable {
    "@type": EntityTypes.REFLECTIVE_ANALYSIS;
    questions: IReflectiveAnalysisQuestion[];
    individualAssignment: string;
    type: ReflectiveAnalysisType;
    createdBy: IUserResource;
}
