import React from "react";

import { SvgIcon, SvgIconProps } from "@sol/uikit/core/icons/SvgIcon";

type Props = { className?: string };

export const Profile = (props: SvgIconProps & Props) => {
    return (
        <SvgIcon {...props} variant={"stroke"}>
            <path d="M12 21a9 9 0 100-18 9 9 0 000 18z" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M17 19v-1.54c0-.786-.281-1.538-.781-2.093-.5-.555-1.178-.867-1.886-.867H9.667c-.708 0-1.386.312-1.886.867A3.131 3.131 0 007 17.459V19M12 11.5a3 3 0 100-6 3 3 0 000 6z"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </SvgIcon>
    );
};
