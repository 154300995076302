import React from "react";

import { SvgIcon, SvgIconProps } from "@sol/uikit/core/icons/SvgIcon";

type Props = { className?: string };

export const PaperPlane = (props: SvgIconProps & Props) => {
    return (
        <SvgIcon {...props} variant={"stroke"}>
            <path d="M21 2.91992L10 14.3599" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M21 2.91992L14 23.7199L10 14.3599L1 10.1999L21 2.91992Z"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </SvgIcon>
    );
};
