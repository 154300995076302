import { Set } from "immutable";
import { isString } from "lodash";
import partial from "lodash/partial";
import { useMemo } from "react";

import { useAuthenticatedUser } from "@sol/authentication";
import { Feature } from "@sol/features";
import {
    IHydraResource,
    IUser,
    IUserResource,
    UserRole,
    getResourceIRI,
    isBriefResource,
    isFollowUpResource,
    isProfessionalSituationResource,
    isResourceResource,
    isSelfEvaluationResource,
    isCommentResource,
} from "@sol/sdk";

export const restrictFeaturesByScope = (user: IUser, resource: IHydraResource) => (featuresSet: Set<Feature>) => {
    const isProvidedUser = (userResource: IUserResource | string) =>
        getResourceIRI(user) === (isString(userResource) ? userResource : getResourceIRI(userResource));

    if (isSelfEvaluationResource(resource)) {
        if (isProvidedUser(resource.createdBy)) {
            // User is the owner
            return featuresSet;
        }
        // User is not the owner
        return featuresSet.remove(Feature.FOLLOW_UPS_EDIT);
    }

    if (isFollowUpResource(resource)) {
        // Self evaluation
        if (isProvidedUser(resource.createdBy)) {
            // User is the owner
            return featuresSet;
        }
        // User is not the owner
        return featuresSet.remove(Feature.FOLLOW_UPS_EDIT);
    }

    if (isBriefResource(resource)) {
        let scopedFeatures = featuresSet;

        if (isProvidedUser(resource.createdBy)) {
            // Authors can edit, delete and duplicate their briefs
            scopedFeatures = scopedFeatures.union([
                Feature.BRIEFS_EDIT,
                Feature.BRIEFS_DELETE,
                Feature.BRIEFS_DUPLICATE,
                Feature.BRIEFS_EDIT_COEDITORS,
            ]);
        } else if (!!resource.coeditors.find(isProvidedUser)) {
            // Coeditors can edit and duplicate their briefs
            scopedFeatures = scopedFeatures.union([Feature.BRIEFS_EDIT, Feature.BRIEFS_DUPLICATE]);
        } else {
            scopedFeatures = scopedFeatures.remove(Feature.BRIEFS_EDIT);
        }

        return scopedFeatures;
    }

    if (isResourceResource(resource)) {
        // By default no user can udpate nor delete a resource
        if (isProvidedUser(resource.createdBy)) {
            // if user is the resource author then he can update AND delete
            return featuresSet.union([Feature.RESOURCE_UPDATE, Feature.RESOURCE_DELETE]);
        } else if (user.roles.includes(UserRole.TRAINER)) {
            // trainer can delete any resource
            return featuresSet.add(Feature.RESOURCE_DELETE);
        }
        // user cannot update nor delete any resource
        return featuresSet;
    }

    if (isProfessionalSituationResource(resource)) {
        // by default, trainers can update professional situations
        const { createdBy, briefs = [] } = resource;

        // TODO: standardize API right management check arround IResource ? or introspection
        // Here there is a hack createdBy can be link or IUserResource;
        if (isProvidedUser(createdBy)) {
            // professional situation is not linked to any briefs
            if (!(briefs.length > 0)) {
                return featuresSet.add(Feature.PROFESSIONAL_SITUATION_DELETE);
            }

            return featuresSet;
        }

        // user cannot update nor delete any briefs
        return featuresSet.remove(Feature.PROFESSIONAL_SITUATION_UPDATE);
    }

    if (isCommentResource(resource)) {
        if (isProvidedUser(resource.createdBy)) {
            // only owners cand delete or edit a comment
            return featuresSet.add(Feature.COMMENTS_EDIT);
        }

        return featuresSet;
    }

    return featuresSet;
};

export const useRestrictedScope = () => {
    const { user } = useAuthenticatedUser();

    return useMemo(() => partial(restrictFeaturesByScope, user), [user]);
};
