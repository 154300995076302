import { ITopicResource } from "@sol/sdk/topics";
import { createApiMutationHook, IApiMutationFunction } from "@sol/sdk/utils/api";
import { mapRelationalValue, RelationalValue } from "@sol/sdk/utils/filters";

import {
    IReflectiveAnalysisQuestion,
    IReflectiveAnalysisResource,
    ReflectiveAnalysisType,
} from "../IReflectiveAnalysisResource";

export interface IReflectiveAnalysisUpdateMutationParameters {
    reflectiveAnalysis: RelationalValue<IReflectiveAnalysisResource>;
    questions: IReflectiveAnalysisQuestion[];
    individualAssignment: RelationalValue<ITopicResource>;
    type: ReflectiveAnalysisType;
}

export type IReflectiveAnalysisUpdateResult = IReflectiveAnalysisResource;

export const reflectiveAnalysisUpdateMutation: IApiMutationFunction<IReflectiveAnalysisUpdateMutationParameters> = ({
    reflectiveAnalysis,
    questions,
    individualAssignment,
    type,
}) => {
    return {
        method: "PUT",
        url: `/reflective_analyses/${mapRelationalValue(reflectiveAnalysis)}`,
        data: {
            questions,
            individualAssignment: mapRelationalValue(individualAssignment),
            type,
        },
    };
};

export const useReflectiveAnalysisUpdate = createApiMutationHook<
    IReflectiveAnalysisUpdateMutationParameters,
    IReflectiveAnalysisUpdateResult
>(reflectiveAnalysisUpdateMutation);
