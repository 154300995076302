import React from "react";

import { SvgIcon, SvgIconProps } from "@sol/uikit/core/icons/SvgIcon";

type Props = { className?: string };

export const Reset = (props: SvgIconProps & Props) => {
    return (
        <SvgIcon {...props} variant={"stroke"}>
            <path
                d="M7.9991 4L4 8.47294M4 8.47294L8.47294 12.472M4 8.47294L10.3649 8.31525C12.3132 8.20793 14.2437 8.73671 15.8653 9.82191C17.487 10.9071 18.712 12.4899 19.3559 14.3319C19.9997 16.1739 20.0004 17.997 20.0001 20.2615"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </SvgIcon>
    );
};
