import React, { Component } from "react";

import HttpErrorDisplay from "./HttpErrorDisplay";
import HttpError from "../errors/HttpError";
import NetworkError from "../errors/NetworkError";

type Props = {
    className?: string;
};

type State = {
    error: any;
    type: null | "http" | "network";
};

class HttpErrorBoundary extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { error: null, type: null };
    }

    componentDidCatch(error: any) {
        if (error instanceof HttpError) {
            this.setState({ error, type: "http" });
        } else if (error instanceof NetworkError) {
            this.setState({ error, type: "network" });
        } else {
            this.setState({ error });
            throw error;
        }
    }

    render() {
        const { children } = this.props;
        const { error, type } = this.state;

        if (error) {
            return <HttpErrorDisplay status={type === "network" ? 500 : error?.status} />;
        }

        return children;
    }
}

export default HttpErrorBoundary;
