import { AxiosPromise } from "axios";
import { useMemo, useCallback } from "react";
import { useQuery, QueryKey, QueryConfig } from "react-query";

import { useApi } from "@sol/sdk/SDKProvider";

import { ISkillWithSelfEvaluationsCountList } from "./ISkillsWithSelfEvaluationsCountList";
import { IPaginated } from "../types/IPaginated";
import { IUserResource } from "../users/IUserResource";
import { getResourceUuid } from "../utils/getResourceUuid";
import { serialize } from "../utils/url";

type Params<TResult = unknown, TError = unknown> = {
    queryKey?: QueryKey;
    learner: IUserResource;
    filters?: {
        officialTitle?: string;
        shortTitle?: string;
        frameworks?: string | string[];
    };
    orderBy?: "officialTitle" | "shortTitle" | "createdAt" | "updatedAt" | "rank";
    order?: "asc" | "desc";
    pagination?: Required<Pick<IPaginated, "page" | "perPage">>;
} & Omit<QueryConfig<TResult, TError>, "queryKey" | "queryFn">;

// TODO: move to utils and handle error handling
const unwrapAPIResult = async <T>(promise: AxiosPromise<T>) => {
    const res = await promise;

    return res.data;
};

const mapFilters = (filters: Params["filters"]) => {
    const { frameworks, officialTitle, shortTitle } = filters || {};
    return filters
        ? {
              officialTitle,
              shortTitle,
              "frameworks.uuid": frameworks,
          }
        : undefined;
};

export const useSkillsWithSelfEvaluationsCount = ({
    queryKey,
    learner,
    filters,
    order = "desc",
    orderBy = "createdAt",
    pagination,
    ...options
}: Params<ISkillWithSelfEvaluationsCountList>) => {
    // Retrieve the API axios instance to make requests
    const api = useApi();

    const url = useMemo(
        () =>
            `/skills/with-evaluation-numbers-of-${getResourceUuid(learner)}${serialize(
                {
                    // Disable pagination on the query if not set
                    ...(!pagination ? { pagination: false } : pagination),
                    [`order[${orderBy}]`]: order,
                    ...mapFilters(filters),
                },
                true,
            )}`,
        [filters, pagination, order, orderBy],
    );

    const queryFn = useCallback(() => unwrapAPIResult(api.get<ISkillWithSelfEvaluationsCountList>(url)), [api, url]);

    return useQuery<ISkillWithSelfEvaluationsCountList>({
        queryKey: queryKey || url,
        queryFn,
        config: options,
    });
};
