import { AxiosPromise } from "axios";
import { useCallback, useMemo } from "react";
import { QueryConfig, QueryKey, useQuery } from "react-query";

import { useApi } from "@sol/sdk/SDKProvider";

import { IFrameworkList } from "./IFrameworkList";
import { IPaginated } from "../types/IPaginated";
import { serialize } from "../utils/url";

export type Params<TResult = unknown, TError = unknown> = {
    queryKey?: QueryKey;
    filters?: {
        title?: string;
        restricted?: boolean;
        skills?: string | string[];
        classrooms?: string | string[];
        learners?: string | string[];
    };
    orderBy?: "title" | "createdAt" | "updatedAt";
    order?: "asc" | "desc";
    pagination?: Required<Pick<IPaginated, "page" | "perPage">>;
} & Omit<QueryConfig<TResult, TError>, "queryKey" | "queryFn">;

// TODO: move to utils and handle error handling
const unwrapAPIResult = async <T>(promise: AxiosPromise<T>) => {
    const res = await promise;

    return res.data;
};

const mapFilters = (filters: Params["filters"]) => {
    const { restricted = true, title, skills, classrooms, learners } = filters || {};
    return {
        restricted: restricted ? 1 : undefined,
        title,
        "skills.uuid": skills,
        "classrooms.uuid": classrooms,
        "classrooms.learners.uuid": learners,
    };
};

export const useFrameworks = ({
    queryKey,
    filters,
    order = "desc",
    orderBy,
    pagination,
    ...options
}: Params<IFrameworkList>) => {
    // Retrieve the API axios instance to make requests
    const api = useApi();

    const url = useMemo(
        () =>
            `/frameworks${serialize(
                {
                    // Disable pagination on the query if not set
                    ...(!pagination ? { pagination: false } : pagination),
                    ...(orderBy && { [`order[${orderBy}]`]: order }),
                    ...mapFilters(filters),
                },
                true,
            )}`,
        [filters, pagination, order, orderBy],
    );

    const queryFn = useCallback(() => unwrapAPIResult(api.get<IFrameworkList>(url)), [api, url]);

    return useQuery<IFrameworkList>({
        queryKey: queryKey || url,
        queryFn,
        config: options,
    });
};
