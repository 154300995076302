import React from "react";

import { SvgIcon, SvgIconProps } from "@sol/uikit/core/icons/SvgIcon";

type Props = { className?: string };

export const Minus = (props: SvgIconProps & Props) => {
    return (
        <SvgIcon {...props} variant={"stroke"}>
            <line x1="7" y1="12" x2="17" y2="12" strokeWidth="2" strokeLinecap="round" />
        </SvgIcon>
    );
};
